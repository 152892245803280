import { IDump } from 'models/dump.model';
import React, { ComponentPropsWithoutRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { ChevronRightIcon, PhotoIcon, MicrophoneIcon, EyeIcon, DocumentIcon } from '@heroicons/react/24/outline';
import { getUrlDump } from 'utilities/urls.utils';
import { Text, Tooltip } from 'components/common/ComponentLibrary';
import useDumpAttachments from 'hooks/dumps/useDumpAttachments';
import pluralize from 'pluralize';
import { useContextMenu } from 'use-context-menu';
import { isFilePathAudio, isFilePathGeneric, isFilePathImage } from 'utilities/storage.utils';
import JoinChildren from 'components/common/JoinChildren';
import DumpRowContextMenu from 'components/dumps/DumpList/DumpRowContextMenu';
import DumpRowContents from './DumpRowContents';
import { useAuthStore } from 'store/auth.store';
import { getAttachmentFilePath } from 'models/attachments/services/rxDb/RxDbAttachmentService';

import './DumpRow.css';
import 'use-context-menu/styles.css';

type Props = {
  searchTerm: string;
  dump: IDump;
} & ComponentPropsWithoutRef<'li'>;

const DumpRow: React.FC<Props> = (props) => {
  const { dump, searchTerm, style, ...listItemProps } = props;

  const [isHovered, setIsHovered] = useState<boolean>(false);

  const { attachments } = useDumpAttachments(dump.id);

  const [currentUser] = useAuthStore((state) => [state.currentUser]);

  const { contextMenu, onContextMenu } = useContextMenu(<DumpRowContextMenu dump={dump} />);

  return (
    <li
      onContextMenu={onContextMenu}
      {...listItemProps}
      style={
        isHovered
          ? {
              ...style,
              backgroundColor: 'rgba(92,202,190,0.25)',
            }
          : style
      }
    >
      {contextMenu}
      <Link
        to={getUrlDump(dump)}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onFocus={() => setIsHovered(true)}
        onBlur={() => setIsHovered(false)}
        className={isHovered ? 'active' : ''}
      >
        <div className='row-link-container'>
          <div className='flex flex-col space-y-2'>
            <DumpRowContents dump={dump} attachments={attachments} searchTerm={searchTerm} />
            {attachments && attachments?.length > 0 && dump.text && (
              <>
                <JoinChildren
                  separator={
                    <span key='separator' className='text-gray-500'>
                      &middot;
                    </span>
                  }
                >
                  {attachments &&
                    attachments?.filter((attachment) => isFilePathImage(getAttachmentFilePath(attachment))).length >
                      0 && (
                      <Tooltip size='s' spacing='s' target={<PhotoIcon className='w-4 text-gray-500' />} scope='local'>
                        <Text>{`${pluralize(
                          'Images',
                          attachments?.filter((attachment) => isFilePathImage(getAttachmentFilePath(attachment)))
                            .length,
                          true,
                        )}`}</Text>
                      </Tooltip>
                    )}
                  {attachments &&
                    attachments?.filter((attachment) => isFilePathGeneric(getAttachmentFilePath(attachment))).length >
                      0 && (
                      <Tooltip
                        size='s'
                        spacing='s'
                        target={<DocumentIcon className='w-4 text-gray-500' />}
                        scope='local'
                      >
                        <Text>{`${pluralize(
                          'Documents',
                          attachments?.filter((attachment) => isFilePathGeneric(getAttachmentFilePath(attachment)))
                            .length,
                          true,
                        )}`}</Text>
                      </Tooltip>
                    )}
                  {attachments &&
                    attachments?.filter((attachment) => isFilePathAudio(getAttachmentFilePath(attachment))).length >
                      0 && (
                      <Tooltip
                        size='s'
                        spacing='s'
                        target={<MicrophoneIcon className='w-4 text-gray-500' />}
                        scope='local'
                      >
                        <Text>{`${pluralize(
                          'Audio clip',
                          attachments?.filter((attachment) => isFilePathAudio(getAttachmentFilePath(attachment)))
                            .length,
                          true,
                        )}`}</Text>
                      </Tooltip>
                    )}
                  {dump.user_id !== currentUser?.id && (
                    <Tooltip size='s' spacing='s' target={<EyeIcon className='w-4 text-gray-500' />} scope='local'>
                      <Text>This is a view only, shared Dump</Text>
                    </Tooltip>
                  )}
                </JoinChildren>
              </>
            )}
          </div>
          <div className='w-6'>
            <ChevronRightIcon className='w-6 text-gray-500' />
          </div>
        </div>
      </Link>
    </li>
  );
};

export default DumpRow;
