import { DumpInterface } from 'models/dumps/interfaces/DumpInterface';
import { trackEvent } from 'models/application/services/TrackingService';
import { TRACKING_EVENT_ENUM } from 'models/application/enums/trackingEventEnum';
import { deleteDump } from 'models/dumps/services/DumpService';
import { toast } from 'react-toastify';

const useDeleteDump = (dump: DumpInterface) => {
  const handleDeleteDump = async () => {
    await deleteDump(dump.id);

    trackEvent(TRACKING_EVENT_ENUM.DUMP_DELETED);

    toast.success('Dump deleted');
  };

  return {
    deleteDump: handleDeleteDump,
  };
};

export default useDeleteDump;
