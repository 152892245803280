import { RxCollection } from "rxdb";
import { DumpDocument, DumpDocumentMethods } from "./dump.document";
import { DumpDocType as DumpDocumentType } from "./dump.schema";

export type DumpCollectionMethods = {
  queryAll: () => Promise<DumpDocument[]>;
};

export type DumpCollection = RxCollection<
  DumpDocumentType,
  DumpDocumentMethods,
  DumpCollectionMethods
>;

export const defaultDumpCollectionsMethods: DumpCollectionMethods = {
  queryAll: async function (this: DumpCollection) {
    return this.find().exec();
  },
};
