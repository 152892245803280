import React from 'react';
import { AudioPreview, GenericFilePreview, ImagePreview } from '../Attachments';
import { STORAGE_BUCKET_ENUM } from 'models/application/enums/ImageEnums';
import { AttachmentInterface } from 'models/attachments/interfaces/AttachmentInterface';
import SupabaseAttachment from 'components/common/SupabaseAttachment';
import { isFilePathAudio, isFilePathGeneric, isFilePathImage } from 'utilities/storage.utils';
import { getAttachmentFilePath } from 'models/attachments/services/rxDb/RxDbAttachmentService';
import SupabaseImageAttachment from 'components/common/SupabaseImageAttachment';

const DumpReadModeAttachments = ({ attachments }: { attachments?: AttachmentInterface[] }) => {
  if (!attachments || attachments.length === 0) {
    return null;
  }

  return (
    <div className='flex space-x-2'>
      {attachments.map((attachment: AttachmentInterface) => {
        if (isFilePathImage(getAttachmentFilePath(attachment))) {
          return (
            <SupabaseImageAttachment
              key={attachment.id}
              path={getAttachmentFilePath(attachment)}
              storageBucket={STORAGE_BUCKET_ENUM.ATTACHMENTS}
            >
              {({ previewSrc, src }) => <ImagePreview src={src || previewSrc} previewSrc={previewSrc || src} />}
            </SupabaseImageAttachment>
          );
        } else if (isFilePathAudio(getAttachmentFilePath(attachment))) {
          return (
            <SupabaseAttachment
              key={attachment.id}
              path={getAttachmentFilePath(attachment)}
              storageBucket={STORAGE_BUCKET_ENUM.ATTACHMENTS}
            >
              {({ src }) => <AudioPreview src={src} duration={attachment.duration} />}
            </SupabaseAttachment>
          );
        } else if (isFilePathGeneric(getAttachmentFilePath(attachment))) {
          return (
            <SupabaseAttachment
              key={attachment.id}
              path={getAttachmentFilePath(attachment)}
              storageBucket={STORAGE_BUCKET_ENUM.ATTACHMENTS}
            >
              {({ src }) => <GenericFilePreview filename={attachment.filename} src={src} />}
            </SupabaseAttachment>
          );
        }
      })}
    </div>
  );
};

export default DumpReadModeAttachments;
