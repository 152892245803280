import React, { useState } from 'react';
import { Button } from 'components/common/ComponentLibrary';
import Cropper from 'react-easy-crop';
import BackdropComponent from 'components/common/dialog/Backdrop.component';
import { cropImage, IPixelCrop } from 'utilities/images.utils';
import { ImageType } from 'react-images-uploading';
import { notifyAboutError } from 'utilities/common/notifyAboutError.utils';

interface IImageCropperModal {
  onDismiss: () => void;
  image: ImageType;
  onComplete: (imageUrl: Promise<any>) => void;
}

const ImageCropperModal = ({ onDismiss, image, onComplete }: IImageCropperModal) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState<number>(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<IPixelCrop | undefined>(undefined);

  const handleComplete = async () => {
    if (croppedAreaPixels) {
      try {
        const imageUrl = await cropImage(image, croppedAreaPixels);
        onComplete(Promise.resolve({ croppedImage: imageUrl, file: image.file }));
      } catch (error) {
        notifyAboutError(error, true, 'Something went wrong cropping your image.');
      }
    } else {
      notifyAboutError('Cropped area is undefined.');
    }
  };

  return (
    <BackdropComponent dismiss={onDismiss}>
      <div className='p-6 flex flex-col space-y-5 md:w-[500px] w-[300px]'>
        <div className='bg-paperdark' style={{ height: 300, position: 'relative', width: '100%' }}>
          <Cropper
            cropShape='round'
            image={image.dataURL}
            crop={crop}
            zoom={zoom}
            aspect={1}
            onCropChange={setCrop}
            onCropComplete={(_, croppedAreaPixels) => {
              setCroppedAreaPixels(croppedAreaPixels);
            }}
            onZoomChange={setZoom}
          />
        </div>

        <Button onClick={handleComplete}>Finish</Button>
      </div>
    </BackdropComponent>
  );
};

export default ImageCropperModal;
