import { ReactNode, useEffect, useState } from 'react';
import { STORAGE_BUCKET_ENUM } from 'models/application/enums/ImageEnums';
import { getSignedUrl } from 'utilities/storage.utils';
import { getPreviewFilePath } from 'utilities/images.utils';

interface ISupabaseImageAttachment {
  children: (props: { src?: string | null; previewSrc?: string | null; isLoading: boolean }) => ReactNode;
  path: string;
  storageBucket: STORAGE_BUCKET_ENUM;
}

const SupabaseImageAttachment = ({ children, path, storageBucket }: ISupabaseImageAttachment) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [previewSrc, setPreviewSrc] = useState<string | null>(null);
  const [src, setSrc] = useState<string | null>(null);

  useEffect(() => {
    (async () => {
      const signedUrlPreview = await getSignedUrl(getPreviewFilePath(path), storageBucket);
      if (signedUrlPreview) {
        setPreviewSrc(signedUrlPreview);
      }

      const signedUrl = await getSignedUrl(path, storageBucket);
      if (signedUrl) {
        setSrc(signedUrl);
      }
      setIsLoading(false);
    })();
  }, []);

  return children?.({
    src,
    previewSrc,
    isLoading,
  });
};

export default SupabaseImageAttachment;
