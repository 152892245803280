import React, { ChangeEvent, useState } from 'react';
import { Button, Input } from 'components/common/ComponentLibrary';
import { XMarkIcon } from '@heroicons/react/24/solid';

interface ISearchInput {
  searchRef?: React.MutableRefObject<HTMLInputElement | null>;
  placeholder?: string;
  defaultValue?: string;
  onChange: (query: string) => void;
  autoFocus?: boolean;
  showClearAction?: boolean;
}

const SearchInput = ({
  searchRef,
  placeholder,
  defaultValue,
  onChange,
  autoFocus,
  showClearAction = true,
}: ISearchInput) => {
  const [query, setQuery] = useState<string>(defaultValue || '');
  return (
    <div className='relative w-full'>
      <Input
        placeholder={placeholder}
        size='l'
        type='text'
        ref={searchRef}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          setQuery(e.target.value);
          onChange(e.target.value);
        }}
        autoFocus={autoFocus}
        value={query}
      />
      {query && showClearAction && (
        <div className='absolute right-0 top-[1px]'>
          <Button
            size='l'
            theme='bare'
            width='square'
            onClick={() => {
              setQuery('');
              onChange('');
            }}
          >
            <Button.Icon icon={<XMarkIcon className='w-5' />} />
          </Button>
        </div>
      )}
    </div>
  );
};

export default SearchInput;
