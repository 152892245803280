import { dumpitDatabase } from 'store/rxdb/dumpitDatabase';
import { v4 as uuidv4 } from 'uuid';
import { getCurrentUTCDateFormatted } from 'utilities/date.utils';
import { TagInterface } from 'models/dumps/interfaces/TagInterface';
import { Observable } from 'rxjs';
import { TagDocument } from 'store/rxdb/tag/tag.document';

export async function createTags(tags: string[], dumpId: string) {
  if (!tags || tags.length === 0) {
    return;
  }

  tags.forEach((tag) => {
    const id = uuidv4().toString();
    const newTag: TagInterface = {
      id,
      name: tag,
      dump_id: dumpId,
      created_at: getCurrentUTCDateFormatted(),
      updated_at: getCurrentUTCDateFormatted(),
    };

    dumpitDatabase.tags.upsert(newTag);
  });
}

export async function deleteTagsByDumpId(dumpId: string) {
  try {
    const tagDocs = await dumpitDatabase.tags.find({ selector: { dump_id: dumpId } }).exec();
    if (!tagDocs) {
      return;
    }

    tagDocs.forEach((tagDoc) => {
      tagDoc.remove();
    });
  } catch (e) {
    return;
  }
}

export async function getAllTags() {
  try {
    return await dumpitDatabase.tags.queryAll();
  } catch (e) {
    return Promise.reject(e);
  }
}

export function getAllTagsObservable(): Observable<TagDocument[]> {
  return dumpitDatabase.tags.find().$;
}
