import { dumpTextContains, IDump } from 'models/dump.model';
import { TAG_STATE } from 'constants/tag.constants';

export const filterDumpsBySearchQuery = (query: string) => (dump: IDump) => {
  if (!query || query.length === 0) {
    return true;
  }

  return dumpTextContains(dump, query);
};

export const filterDumpsByTags = (tags: any) => (dump: IDump) => {
  const tagsToInclude = Object.entries(tags)
    .filter(([_, status]) => status === TAG_STATE.SELECTED)
    .map(([tag]) => tag);

  const tagsToExclude = Object.entries(tags)
    .filter(([_, status]) => status === TAG_STATE.UNSELECTED)
    .map(([tag]) => tag);
  if (tagsToInclude.length === 0 && tagsToExclude.length === 0) {
    return true;
  }

  if (!dump.tags) {
    return false;
  }

  const includesAllSelectedTags = tagsToInclude.length === 0 || tagsToInclude.every((tag) => dump?.tags?.includes(tag));
  const doesNotIncludeExcludedTags =
    tagsToExclude.length === 0 || dump.tags.every((tag) => !tagsToExclude.includes(tag));

  return includesAllSelectedTags && doesNotIncludeExcludedTags;
};
