import React from 'react';
import BackdropComponent from 'components/common/dialog/Backdrop.component';
import ProfileNameForm from './ProfileNameForm';
import { Heading } from 'components/common/ComponentLibrary';
import { useTranslation } from 'react-i18next';

const ProfileNameModal = ({ onDismiss }: { onDismiss: () => void }) => {
  const { t } = useTranslation('translations');

  return (
    <BackdropComponent dismiss={onDismiss}>
      <div className='w-full p-6'>
        <Heading size='l' textAlign='left' spacing='m'>
          {t('nameModal.title')}
        </Heading>
        <ProfileNameForm onChanged={onDismiss} />
      </div>
    </BackdropComponent>
  );
};

export default ProfileNameModal;
