import { useEffect, useState } from 'react';
import { Subscription } from 'rxjs';
import { IDump } from 'models/dump.model';
import { getAllDumps } from 'models/dumps/services/rxDb/RxDbDumpService';
import { sortDumps } from 'utilities/dumps/dumps.utils';
import { dumpitDatabase } from 'store/rxdb/dumpitDatabase';

const useDumps = () => {
  let subscription: Subscription | undefined;
  const [error, setError] = useState<Error | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [dumps, setDumps] = useState<IDump[]>([]);

  useEffect(() => {
    const fetchDumps = async () => {
      setIsLoading(true);
      try {
        const docs = await getAllDumps();
        const sortedDumps = sortDumps(docs.map((doc) => doc.asDump()).filter((dump) => dump.is_to_be_deleted !== true));
        setDumps(sortedDumps);
      } catch (err) {
        setError(err as Error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchDumps();
  }, []);

  useEffect(() => {
    subscription = dumpitDatabase.dumps.$.subscribe((event) => {
      const newDump: IDump = {
        id: event.documentId,
        text: event.documentData.text,
        updated_at: event.documentData.updated_at,
        last_edited_at: event.documentData.last_edited_at,
        created_at: event.documentData.created_at,
        user_id: event.documentData.user_id,
        is_to_be_deleted: event.documentData.is_to_be_deleted,
        is_pinned: event.documentData.is_pinned || false,
      };
      switch (event.operation) {
        case 'INSERT':
        case 'UPDATE':
          setDumps([
            ...(event.operation === 'INSERT'
              ? dumps
              : dumps.filter((dump) => dump.id !== event.documentId || dump.is_to_be_deleted === true)),
            ...(newDump.is_to_be_deleted ? [] : [newDump]),
          ]);
          break;
        case 'DELETE':
          setDumps(dumps.filter((dump) => dump.id !== event.documentId));
          break;
      }
      return () => {
        subscription?.unsubscribe();
      };
    });
  }, [dumps]);

  return {
    isLoading,
    error,
    dumps,
  };
};

export default useDumps;
