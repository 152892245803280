import { dumpitDatabase } from 'store/rxdb/dumpitDatabase';
import { DumpInterface } from 'models/dumps/interfaces/DumpInterface';
import { getCurrentUTCDateFormatted } from 'utilities/date.utils';
import { uniquifyArray } from 'utilities/array.utils';
import { DumpDocument } from 'store/rxdb/dump/dump.document';

export async function createDump(dump: DumpInterface) {
  const newDump: DumpInterface = {
    ...dump,
    created_at: getCurrentUTCDateFormatted(),
    updated_at: getCurrentUTCDateFormatted(),
    last_edited_at: getCurrentUTCDateFormatted(),
  };

  delete newDump.tags; // @todo-phil We should not add tags.

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // @todo-phil I could not fix this TS issue :(
  return await dumpitDatabase.dumps.insert(newDump);
}

export async function updateDump(dumpId: string, dump: Partial<DumpInterface>, updateLastEditedAt = true) {
  try {
    const dumpDoc = await dumpitDatabase.dumps.findOne({ selector: { id: dumpId } }).exec();
    if (!dumpDoc) {
      return Promise.reject('No dump found');
    }

    const updatedDoc = {
      ...dump,
      updated_at: getCurrentUTCDateFormatted(),
    };
    if (updateLastEditedAt) {
      updatedDoc.last_edited_at = getCurrentUTCDateFormatted();
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    delete updatedDoc.attachments; // @todo-phil Temp to remove a field that should not be there.

    delete updatedDoc.tags; // @todo-phil We should not add tags.

    await dumpDoc.getLatest().update({
      $set: updatedDoc,
    });

    return Promise.resolve();
  } catch (e) {
    return Promise.reject(e);
  }
}

export async function markDumpSynced(dumpId: string, additionalData: Partial<DumpInterface>) {
  const existingDump = await getDumpById(dumpId);
  if (existingDump) {
    await existingDump.getLatest().update({
      $set: {
        is_synced: true,
        ...additionalData,
      },
    });
  }
}

export async function getDumpById(dumpId: string): Promise<DumpDocument | null> {
  try {
    const dump = await dumpitDatabase.dumps
      .findOne({
        selector: {
          id: dumpId,
        },
      })
      .exec();
    if (!dump) {
      return null;
    }

    return dump;
  } catch (e) {
    return null;
  }
}

export async function getAllDumps(): Promise<DumpDocument[]> {
  try {
    return await dumpitDatabase.dumps.queryAll();
  } catch (e) {
    return Promise.reject(e);
  }
}

export const extractTags = (text?: string) => {
  if (!text || text.length <= 1) {
    return [];
  }

  const hashtagRegex = /(?<=^|\s)#([\p{L}\p{N}]{1,29})(?=\s|$|\W)/gu;
  const matches = text.match(hashtagRegex);

  if (!matches) {
    return [];
  }

  return uniquifyArray(matches.map((tag) => tag.slice(1)));
};

export async function getAllUnsyncedDumps(): Promise<DumpDocument[] | null> {
  try {
    const encryptedDumps = await dumpitDatabase.dumps
      .find({
        selector: {
          is_synced: false,
        },
      })
      .exec();
    if (!encryptedDumps) {
      return null;
    }

    return encryptedDumps;
  } catch (e) {
    return null;
  }
}

export async function upsertDump(dump: Partial<DumpInterface>) {
  const existingDumpMeta = await getDumpById(dump.id!);
  if (existingDumpMeta) {
    return await existingDumpMeta.getLatest().update({
      $set: dump,
    });
  } else {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // @todo-phil I could not fix this TS issue :(
    return await dumpitDatabase.dumps.insert(dump);
  }
}
