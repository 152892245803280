import React from 'react';
import { MotionEffectPopIn } from 'components/common/MotionEffects';
import { WaveIcon } from 'components/common/SpecialIcons';

const DumpRowAudioPreview = () => (
  <div className='relative inline-block'>
    <div className='cursor-pointer w-12 h-12'>
      <MotionEffectPopIn>
        <div className='relative'>
          <div className='w-12 h-12 m-auto text-center flex items-center bg-cyan/25 justify-center rounded-xl'>
            <WaveIcon className='w-6 text-cyan' />
          </div>
        </div>
      </MotionEffectPopIn>
    </div>
  </div>
);

export default DumpRowAudioPreview;
