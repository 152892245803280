const uuid_patterns = {
  1: /^[0-9A-F]{8}-[0-9A-F]{4}-1[0-9A-F]{3}-[0-9A-F]{4}-[0-9A-F]{12}$/i,
  2: /^[0-9A-F]{8}-[0-9A-F]{4}-2[0-9A-F]{3}-[0-9A-F]{4}-[0-9A-F]{12}$/i,
  3: /^[0-9A-F]{8}-[0-9A-F]{4}-3[0-9A-F]{3}-[0-9A-F]{4}-[0-9A-F]{12}$/i,
  4: /^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i,
  5: /^[0-9A-F]{8}-[0-9A-F]{4}-5[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i,
};

export const isUUID = (input: string, version: 1 | 2 | 3 | 4 | 5 = 4) => {
  if (typeof input === 'string') {
    if (
      Object.keys(uuid_patterns).includes(typeof version === 'string' ? version : String(version))
    ) {
      return uuid_patterns[version].test(input);
    } else {
      return Object.values(uuid_patterns).some((pattern) => pattern.test(input));
    }
  }
  return false;
};
