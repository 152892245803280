import { captureException, captureMessage } from '@sentry/react';
import supabase from 'store/supabase/supabase';
import { getCurrentProfileId } from 'models/profiles/services/ProfileService';
import { getAllUnsyncedAttachments, upsertAttachment } from './rxDb/RxDbAttachmentService';
import { uploadAttachment } from './AttachmentService';
import {
  getLocalStorageLastSyncedAttachments,
  setLocalStorageLastSyncedAttachments,
} from 'models/application/services/LocalStorageService';
import { AttachmentInterface } from 'models/attachments/interfaces/AttachmentInterface';
import { SupabaseAttachmentService } from './supabase/SupabaseAttachmentService';

export async function pushUnsyncedAttachments(): Promise<boolean> {
  const unsyncedAttachments = await getAllUnsyncedAttachments();
  if (unsyncedAttachments && unsyncedAttachments.length > 0) {
    const currentProfileId = await getCurrentProfileId();
    if (!currentProfileId) {
      return false;
    }

    await Promise.all(
      unsyncedAttachments.map(async (unsyncedAttachment) => {
        try {
          const asAttachment = {
            ...unsyncedAttachment.asAttachment(),
            _deleted: unsyncedAttachment.asAttachment().is_to_be_deleted,
          };
          delete asAttachment.is_synced;
          delete asAttachment.is_to_be_deleted;

          await uploadAttachment(asAttachment);
        } catch (e) {
          captureMessage(
            `pushUnsyncedAttachments: userId[${currentProfileId}], attachmentId[${unsyncedAttachment.id}].`,
          );
          captureException(e);
        }
      }),
    );
  }

  return true;
}

export async function pullLatestAttachments(): Promise<boolean> {
  try {
    const currentProfileId = await getCurrentProfileId();
    if (!currentProfileId) {
      return false;
    }

    const supabaseAttachmentService = new SupabaseAttachmentService(supabase);
    const attachments = await supabaseAttachmentService.getAttachments(getLocalStorageLastSyncedAttachments());
    if (!attachments) {
      return false;
    }

    await Promise.all(
      attachments.map(async (_attachment) => {
        const attachment: AttachmentInterface = {
          id: _attachment.id,
          dump_id: _attachment.dump_id,
          duration: _attachment.duration,
          filename: _attachment.filename,
          user_id: _attachment.user_id,
          created_at: _attachment.created_at,
          updated_at: _attachment.updated_at,
          _deleted: _attachment._deleted,
          is_synced: true,
          file_data: undefined,
        };

        await upsertAttachment(attachment);
        setLocalStorageLastSyncedAttachments(attachment.updated_at);
      }),
    );

    return true;
  } catch (error) {
    captureException(error);
    return false;
  }
}
