import { Button } from 'components/common/ComponentLibrary';
import { DumpItIcon } from 'components/common/SpecialIcons';
import React, { useState } from 'react';
import { SignUpModal } from '../SignUp';
import { LoginModal } from '../Login';

const LandingHeader = () => {
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [isSignUpModalOpen, setIsSignUpModalOpen] = useState(false);

  return (
    <div className='flex items-center w-full justify-between px-5'>
      <div>
        <DumpItIcon className='w-16 md:w-18' />
      </div>
      <div className='md:flex gap-1 hidden'>
        <Button onClick={() => setIsLoginModalOpen(true)} theme='bare'>
          Sign in
        </Button>
        <Button onClick={() => setIsSignUpModalOpen(true)}>Sign up</Button>
        {isLoginModalOpen && <LoginModal onDismiss={() => setIsLoginModalOpen(false)} />}
        {isSignUpModalOpen && <SignUpModal onDismiss={() => setIsSignUpModalOpen(false)} />}
      </div>
      <div className='md:hidden gap-1 flex'>
        <Button onClick={() => setIsLoginModalOpen(true)} theme='ghost' size='s'>
          Sign in
        </Button>
      </div>
    </div>
  );
};

export default LandingHeader;
