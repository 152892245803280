import React from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { useHotkeys } from 'react-hotkeys-hook';
import { getUrlDumps } from 'utilities/urls.utils';
import { Button, ContentLayout } from 'components/common/ComponentLibrary';
import { Bars4Icon } from '@heroicons/react/24/outline';
import { DumpCreate } from 'components/dumps/Create';
import { ROUTE_DIRECTION_ENUM, ROUTE_MOTION_VARIANTS } from 'utilities/motion.utils';
import useDocumentTitle from 'hooks/application/useDocumentTitle';

const EnterDumpScreen: React.FC = () => {
  const navigate = useNavigate();

  useDocumentTitle('Write');

  useHotkeys('right', () => navigate(getUrlDumps()), []);

  return (
    <motion.div
      custom={{ direction: ROUTE_DIRECTION_ENUM.BACKWARD }}
      initial='initial'
      animate='in'
      variants={ROUTE_MOTION_VARIANTS}
    >
      <ContentLayout hasHScreen={true}>
        <ContentLayout.Top>
          <div className='flex items-center justify-end'>
            <Button width='none' size='l' theme='bare' href={getUrlDumps()}>
              <Button.Icon icon={<Bars4Icon className='w-8' />} />
            </Button>
          </div>
        </ContentLayout.Top>
        <ContentLayout.Content>
          <div className='flex flex-col flex-1'>
            <DumpCreate />
          </div>
        </ContentLayout.Content>
      </ContentLayout>
    </motion.div>
  );
};

export default EnterDumpScreen;
