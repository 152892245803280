import React from 'react';
import BackdropComponent from 'components/common/dialog/Backdrop.component';

const AttachmentImageModal = ({ onDismiss, src }: { onDismiss: () => void; src: string }) => (
  <BackdropComponent dismiss={onDismiss}>
    <div className='w-full max-w-2xl relative rounded-2xl'>
      <img src={src} alt='Preview-large' className='rounded-2xl' />
    </div>
  </BackdropComponent>
);

export default AttachmentImageModal;
