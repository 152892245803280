import React from 'react';
import { orderBy } from 'lodash';
import DumpTagItem from './DumpTagItem';
import { useSearchStore } from 'store/search.store';
import { TAG_STATE } from 'constants/tag.constants';

const DumpTagList = (props: {
  tags: string[];
  maxElements?: number;
  searchTerm: string;
  onToggleTag: (searchTerm: string) => void;
}) => {
  const { tags, maxElements, searchTerm, onToggleTag } = props;

  const visibleTags = (() => {
    if (maxElements) {
      if (searchTerm && !searchTerm.startsWith('#')) {
        return orderBy(tags, (tag) => (tag.indexOf(searchTerm) >= 0 ? 1 : -1), 'desc').slice(
          0,
          Math.min(tags.length, maxElements),
        );
      }
      return tags.slice(0, Math.min(tags.length, maxElements));
    }
    return tags;
  })();

  const [stateTags] = useSearchStore((state) => [state.tags]);
  const selectedTags = Object.keys(stateTags).filter((key) => stateTags[key] === TAG_STATE.SELECTED);

  return (
    <ul className='flex pb-3 overflow-x-auto overflow-y-hidden gap-x-2 text-xs text-dark dark:text-bright dumpit-scrollbar'>
      {visibleTags.map((tag) => (
        <DumpTagItem
          key={tag}
          tag={tag}
          searchTerm={searchTerm}
          onToggleTag={onToggleTag}
          isActive={selectedTags.includes(tag)}
        />
      ))}
    </ul>
  );
};

export default DumpTagList;
