import { useEffect } from 'react';

// Fixes this: https://stackoverflow.com/questions/37112218/css3-100vh-not-constant-in-mobile-browser
// @todo: Check back to see if this ever gets fixed!

const useIOSVhFix = () => {
  const setInnerHeight = () => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  };

  useEffect(() => {
    setInnerHeight();
    window.addEventListener('resize', setInnerHeight);

    return () => {
      window.removeEventListener('resize', setInnerHeight);
    };
  }, []);
};

export default useIOSVhFix;
