import React from 'react';

type ILabel = {
  name: string;
} & React.ComponentPropsWithoutRef<'label'>;

const Label = ({ children, name, ...labelProps }: ILabel) => {
  return (
    <label className='flex flex-col relative' htmlFor={name} {...labelProps}>
      <span className='mb-2 text-xs font-semibold uppercase tracking-widest text-gray-600'>
        {children}
      </span>
    </label>
  );
};

Label.displayName = 'Label';

export default Label;
