import { SIGNUPS_INVITE_ONLY } from 'constants/application.constants';
import { SUPABASE_ERROR_DESCRIPTION_SIGNUPS_NOT_ALLOWED } from 'constants/supabase.constants';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { notifyAboutError } from 'utilities/common/notifyAboutError.utils';

const useSignupErrorHandler = () => {
  const [searchParams] = useSearchParams();
  const errorDescription = searchParams.get('error_description');

  useEffect(() => {
    if (errorDescription) {
      notifyAboutError(
        new Error(errorDescription),
        true,
        errorDescription === SUPABASE_ERROR_DESCRIPTION_SIGNUPS_NOT_ALLOWED ? SIGNUPS_INVITE_ONLY : errorDescription,
      );
    }
  }, [errorDescription]);
};

export default useSignupErrorHandler;
