import React, { useState } from 'react';
import { MinusCircleIcon } from '@heroicons/react/24/solid';
import AttachmentAudioModal from './AttachmentAudioModal';
import { MotionEffectPopIn } from 'components/common/MotionEffects';
import LoadingPlaceholder from './LoadingPlaceholder';
import { WaveIcon } from 'components/common/SpecialIcons';
import { Text } from 'components/common/ComponentLibrary';
import { formatSecondsToHoursMinutes } from 'utilities/audio.utils';

const AudioPreview = ({ src, onRemove, duration }: { src: any; onRemove?: () => void; duration?: number }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  if (!src) {
    return <LoadingPlaceholder />;
  }

  return (
    <div className='relative group inline-block'>
      {onRemove && (
        <MinusCircleIcon
          className='w-6 text-red/60 cursor-pointer hidden group-hover:block absolute z-[500] -right-2 -top-2 hover:text-red rounded-2xl'
          onClick={onRemove}
        />
      )}
      <div onClick={() => setIsModalOpen(true)} className='cursor-pointer w-18 h-18'>
        <MotionEffectPopIn>
          <div className='relative'>
            <div className='hover:bg-cyan/30 w-18 h-18 m-auto text-center flex items-center bg-cyan/25 justify-center rounded-xl'>
              <WaveIcon className='w-10 text-cyan' />
            </div>
            {duration && duration > 0 ? (
              <div className='absolute bottom-1 right-1'>
                <Text color='cyan' size='s' tag='div'>
                  <span className='text-[11px]'>{formatSecondsToHoursMinutes(duration)}</span>
                </Text>
              </div>
            ) : null}
          </div>
        </MotionEffectPopIn>
      </div>
      {isModalOpen && (
        <AttachmentAudioModal
          src={src instanceof File ? URL.createObjectURL(src) : src}
          onDismiss={() => setIsModalOpen(false)}
        />
      )}
    </div>
  );
};

export default AudioPreview;
