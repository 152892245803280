import { mountStoreDevtool } from 'simple-zustand-devtools';
import { create } from 'zustand';

export type IDraftAttachment = {
  src: string | null;
  file: File;
};

export type IDumpDraftStore = {
  text: string;
  audio: File[];
  attachments: IDraftAttachment[];

  setText: (text: string) => void;
  setAudio: (audio: File[]) => void;
  setAttachments: (attachments: IDraftAttachment[]) => void;
  resetStore: () => void;
  isEmpty: () => boolean;
};

export const useDumpDraftStore = create<IDumpDraftStore>((set, getState) => ({
  text: '',
  audio: [],
  attachments: [],

  setText: (text) =>
    set(() => ({
      text,
    })),

  setAudio: (audio) =>
    set(() => ({
      audio,
    })),

  setAttachments: (attachments) =>
    set(() => ({
      attachments,
    })),

  resetStore: () =>
    set(() => ({
      text: '',
      audio: [],
      attachments: [],
    })),

  isEmpty: () =>
    getState().text?.trim().length === 0 && getState().attachments.length === 0 && getState().audio.length === 0,
}));

mountStoreDevtool('DumpDraftStore', useDumpDraftStore);
