import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { useHotkeys } from 'react-hotkeys-hook';
import { getUrlDumps } from 'utilities/urls.utils';
import { Button, ContentLayout, Menu, Text, Tooltip } from 'components/common/ComponentLibrary';
import { ChevronLeftIcon, DocumentDuplicateIcon, EllipsisVerticalIcon } from '@heroicons/react/24/outline';
import { IRoute } from '../routes';
import { ROUTE_DIRECTION_ENUM, ROUTE_MOTION_VARIANTS } from 'utilities/motion.utils';
import useDocumentTitle from 'hooks/application/useDocumentTitle';
import { formatDistanceToNowInUsersTimezone } from 'utilities/date.utils';
import { shortenString } from 'utilities/common/strings.utils';
import { DumpReadMode } from 'components/dumps/ReadMode';
import { DumpInterface } from 'models/dumps/interfaces/DumpInterface';
import { AttachmentInterface } from 'models/attachments/interfaces/AttachmentInterface';
import { useTranslation } from 'react-i18next';
import { DumpCopyAction } from 'components/dumps/Actions';

const DumpDetailReadMode = ({ dump, attachments }: { dump: DumpInterface; attachments?: AttachmentInterface[] }) => {
  const { t } = useTranslation('translations');

  const navigate = useNavigate();

  useDocumentTitle(dump?.text ? shortenString(dump.text, 20) : 'Shared Dump');

  useHotkeys('left', () => navigate(getUrlDumps(), { state: { previousRoute: 'dump_detail' } }), []);

  const handleGoBack = () => {
    navigate(getUrlDumps(), { state: { previousRoute: 'dump_detail' as IRoute } });
  };

  const location = useLocation();
  const isBackwards = location.state?.previousRoute === getUrlDumps();

  return (
    <motion.div
      custom={{ direction: isBackwards ? ROUTE_DIRECTION_ENUM.BACKWARD : ROUTE_DIRECTION_ENUM.FORWARD }}
      initial='initial'
      animate='in'
      variants={ROUTE_MOTION_VARIANTS}
    >
      <ContentLayout hasHScreen={true}>
        <ContentLayout.Top>
          <div className='flex items-center justify-between'>
            <Button
              size='l'
              theme='bare'
              width='none'
              onClick={() => {
                handleGoBack();
              }}
            >
              <Button.Icon icon={<ChevronLeftIcon className='w-6' />} />
            </Button>

            <div>
              <Tooltip
                width='full'
                size='m'
                spacing='none'
                target={
                  <Button size='l' theme='bare' width='none' color='white'>
                    <Button.Icon icon={<EllipsisVerticalIcon className='h-6 text-cyan' />} />
                  </Button>
                }
                isInteractive={true}
                scope='global'
                trigger='click'
              >
                <Menu>
                  <DumpCopyAction dump={dump}>
                    {({ copyDump }: { copyDump: () => void }) => (
                      <Menu.Item onClick={copyDump} icon={<DocumentDuplicateIcon className='h-5' />}>
                        <Text color='inherit'>{t('screens.list.contextMenu.copy')}</Text>
                      </Menu.Item>
                    )}
                  </DumpCopyAction>
                  <Menu.Item>
                    <Text color='gray-500' textAlign='center' size='s'>
                      {`${dump?.last_edited_at ? 'Updated' : 'Created'}`}{' '}
                      {formatDistanceToNowInUsersTimezone(dump.last_edited_at ?? dump.created_at)}
                    </Text>
                  </Menu.Item>
                </Menu>
              </Tooltip>
            </div>
          </div>
        </ContentLayout.Top>
        <ContentLayout.Content>
          <div className='flex flex-col flex-1'>
            <DumpReadMode dump={dump} attachments={attachments} />
          </div>
        </ContentLayout.Content>
      </ContentLayout>
    </motion.div>
  );
};

export default DumpDetailReadMode;
