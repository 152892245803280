import React from 'react';
import { MotionEffectPopIn } from 'components/common/MotionEffects';
import { Text } from 'components/common/ComponentLibrary';
import { getFileExtension } from 'utilities/common/file.utils';

const DumpRowGenericPreview = ({ filename }: { filename: string }) => {
  const extension = getFileExtension(filename);

  return (
    <div className='relative inline-block'>
      <div className='cursor-pointer w-12 h-12'>
        <MotionEffectPopIn>
          <div className='relative'>
            <div className='absolute rounded-xl w-full h-full' />
            <div className='w-12 h-12 bg-bright dark:bg-dark rounded-xl'>
              <div className='w-full h-full text-cyan/50 flex items-center justify-center'>
                <Text size='s' lineClamp={1} color='inherit' fontWeight='bold'>
                  {extension?.toUpperCase()}
                </Text>
              </div>
            </div>
          </div>
        </MotionEffectPopIn>
      </div>
    </div>
  );
};

export default DumpRowGenericPreview;
