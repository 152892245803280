import SettingRow from './SettingRow';
import SettingRowContent from './subComponents/SettingRowContent';
import SettingRowLabel from './subComponents/SettingRowLabel';
import SettingRowEnd from './subComponents/SettingRowEnd';

SettingRow.Content = SettingRowContent;
SettingRow.Label = SettingRowLabel;
SettingRow.End = SettingRowEnd;

export { SettingRow };
