import React from 'react';
import { Text } from 'components/common/ComponentLibrary';
import DownloadIOSApp from 'components/application/DownloadIOSApp';
import SignupForWeb from 'components/application/SignupForWeb';
import DownloadAndroidApp from 'components/application/DownloadAndroidApp';

const LandingBottomPromo = () => (
  <div className='md:px-0 px-10'>
    <h3 className='text-center text-3xl md:text-5xl leading-tight tracking-tighter font-light text-gray-600 line-clamp-none mb-5'>
      Start Using <strong>Dump it!</strong>
    </h3>
    <Text size='l' fontWeight='normal' spacing='l' textAlign='center' color='gray-500'>
      Download our iOS or Android app, or use it on the web.
    </Text>
    <div className='flex flex-col md:flex-row gap-5 items-center justify-center'>
      <DownloadIOSApp />
      <DownloadAndroidApp />
      <SignupForWeb />
    </div>
  </div>
);

export default LandingBottomPromo;
