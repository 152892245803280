export const ALLOWED_FILE_EXTENSIONS = [
  'jpg',
  'png',
  'jpeg',
  'pdf',
  'doc',
  'docx',
  'ppt',
  'pptx',
  'xls',
  'xlsx',
  'csv',
  'txt',
];
