import React, { forwardRef, useState } from 'react';

import { UserIcon } from '@heroicons/react/24/solid';
import classNames from 'classnames';

interface AvatarInterface {
  alt: string;
  src?: string | null;
  size: 'xxs' | 'xs' | 's' | 'm' | 'xxl' | 'inherit';
  initials?: string;
}

const Avatar = forwardRef<HTMLImageElement, AvatarInterface>(
  ({ src, size = 'xxl', alt, initials }: AvatarInterface, ref) => {
    const [isError, setIsError] = useState<boolean>(false);

    const avatarClassNames = classNames({
      'relative flex items-center justify-center shrink-0 overflow-hidden': true,
      'transition ease-in-out': true,
      'bg-zinc-200 dark:bg-zinc-100': true,
      'rounded-full': true,
      'h-[100%] w-[100%]': size === 'inherit',
      'w-40 h-40': size === 'xxl',
      'w-14 h-14': size === 'm',
      'w-11 h-11': size === 's',
      'h-6 w-6': size === 'xs',
      'h-4 w-4': size === 'xxs',
    });

    const initialsClasses = classNames({
      'text-gray-500 dark:text-gray-500 leading-none font-black': true,
      'text-[6px]': size === 'xxs',
      'text-[8px]': size === 'xs',
      'text-sm': size === 's',
      'text-md': size === 'm',
      'text-3xl': size === 'xxl',
    });

    const getFallback = () => {
      if (initials) {
        return <span className={initialsClasses}>{initials}</span>;
      }

      return <UserIcon className='w-2/4 text-gray-400' />;
    };

    return (
      <div className={avatarClassNames} ref={ref}>
        {(!src || isError) && getFallback()}

        {src && !isError && (
          <img src={src} alt={alt} className='w-full h-full object-cover' onError={() => setIsError(true)} />
        )}
      </div>
    );
  },
);

Avatar.displayName = 'Avatar';

export default Avatar;
