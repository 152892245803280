import React from 'react';
import { useField } from 'formik';
import { toast } from 'react-toastify';
import DumpActions from '../DumpActions';
import { useFilePicker } from 'use-file-picker';
import { FileAmountLimitValidator, FileSizeValidator, FileTypeValidator } from 'use-file-picker/validators';
import {
  MAX_ATTACHMENTS_ALLOWED,
  MAX_ATTACHMENTS_REACHED_MESSAGE,
  MAX_FILE_SIZE,
} from 'constants/attachment.constants';
import { getDraftAttachmentsFromFiles, handleFilesRejected } from 'utilities/dumps/dumpAttachment.utils';
import { ALLOWED_FILE_EXTENSIONS } from 'constants/file.constants';
import { captureMessage } from '@sentry/react';

const DumpEditActions = ({ onRecord }: { onRecord: () => void }) => {
  const [fieldAttachments, , helpersAttachments] = useField('attachments');

  const { openFilePicker } = useFilePicker({
    readAs: 'DataURL',
    accept: ALLOWED_FILE_EXTENSIONS.map((extension) => `.${extension}`),
    multiple: true,
    validators: [
      new FileAmountLimitValidator({ max: 3 }),
      new FileTypeValidator(ALLOWED_FILE_EXTENSIONS),
      new FileSizeValidator({ maxFileSize: MAX_FILE_SIZE }),
    ],
    onFilesSuccessfullySelected: async (data) => {
      if (data.plainFiles.length + fieldAttachments.value.length > MAX_ATTACHMENTS_ALLOWED) {
        toast.error(MAX_ATTACHMENTS_REACHED_MESSAGE);
        captureMessage(MAX_ATTACHMENTS_REACHED_MESSAGE);
        return;
      }

      const newAttachments = await getDraftAttachmentsFromFiles(data.plainFiles);
      helpersAttachments.setValue([...fieldAttachments.value, ...newAttachments]);
    },
    onFilesRejected: handleFilesRejected,
  });

  return (
    <>
      <DumpActions
        onImageUpload={() => {
          if (fieldAttachments.value.length >= MAX_ATTACHMENTS_ALLOWED) {
            toast.error(MAX_ATTACHMENTS_REACHED_MESSAGE);
            captureMessage(MAX_ATTACHMENTS_REACHED_MESSAGE);
            return;
          }

          openFilePicker();
        }}
        onFileUpload={() => {
          if (fieldAttachments.value.length >= MAX_ATTACHMENTS_ALLOWED) {
            toast.error(MAX_ATTACHMENTS_REACHED_MESSAGE);
            captureMessage(MAX_ATTACHMENTS_REACHED_MESSAGE);
            return;
          }

          openFilePicker();
        }}
        onRecord={() => {
          if (fieldAttachments.value.length >= MAX_ATTACHMENTS_ALLOWED) {
            toast.error(MAX_ATTACHMENTS_REACHED_MESSAGE);
            captureMessage(MAX_ATTACHMENTS_REACHED_MESSAGE);
            return;
          }

          onRecord();
        }}
      />
    </>
  );
};

export default DumpEditActions;
