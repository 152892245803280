import { ReactNode, useEffect, useState } from 'react';
import { STORAGE_BUCKET_ENUM } from 'models/application/enums/ImageEnums';
import { getSignedUrl } from 'utilities/storage.utils';

interface ISupabaseAttachment {
  children: (props: { src?: string | null; isLoading: boolean }) => ReactNode;
  path: string;
  storageBucket: STORAGE_BUCKET_ENUM;
}

const SupabaseAttachment = ({ children, path, storageBucket }: ISupabaseAttachment) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [src, setSrc] = useState<string | null>(null);

  useEffect(() => {
    (async () => {
      const signedUrl = await getSignedUrl(path, storageBucket);
      if (signedUrl) {
        setSrc(signedUrl);
      }
      setIsLoading(false);
    })();
  }, []);

  return children?.({
    src,
    isLoading,
  });
};

export default SupabaseAttachment;
