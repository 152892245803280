import React from 'react';
import { DumpItIcon } from 'components/common/SpecialIcons';
import Footer from 'components/application/Footer';
import { Heading, Hyperlink, Text } from 'components/common/ComponentLibrary';
import { getUrlHome } from 'utilities/urls.utils';
import useDocumentTitle from 'hooks/application/useDocumentTitle';

const ImpressumScreen = () => {
  useDocumentTitle('Impressum');

  return (
    <div className='m-auto flex min-h-screen flex-col'>
      <main className='m-auto w-full max-w-7xl px-5'>
        <div className='flex items-center text-center justify-center my-5'>
          <Hyperlink href={getUrlHome()}>
            <DumpItIcon className='w-24' />
          </Hyperlink>
        </div>

        <Heading size='xxl' spacing='m'>
          Impressum
        </Heading>

        <Text size='l' spacing='m' fontWeight='bold'>
          Dump it!
        </Text>

        <Text fontWeight='bold'>Angaben gemäß § 5 TMG:</Text>
        <Text>Wolff Pack Ltd</Text>
        <Text>Agiou Georgiou 52</Text>
        <Text spacing='l'>7737 Maroni, Larnaca, Cyprus</Text>

        <Text fontWeight='bold'>Vertreten durch:</Text>
        <Text spacing='l'>Willi Wolff</Text>

        <Text fontWeight='bold'>Contact:</Text>
        <Text spacing='l'>hello@dumpit.app</Text>

        <Text fontWeight='bold' size='l' spacing='m'>
          Disclaimer
        </Text>
        <Text fontWeight='semibold'>Liability for contents</Text>
        <Text spacing='m'>
          The contents of the site were created with the greatest care. However, no guarantee can be given that the
          content is correct, complete or up-to-date. As a service provider we are responsible according to § 7(1) TMG
          for our own contents on these pages according to the general laws. According to §§ 8 to 10 TMG, we are not
          obliged to monitor transmitted or stored third-party information or to investigate circumstances that indicate
          illegal activity. Obligations to remove or block the use of information in accordance with general laws remain
          unaffected by this. A liability in this respect is however only possible from the time of knowledge of a
          concrete infringement. As soon as we become aware of such infringements, we will remove the content
          immediately.
        </Text>
        <Text fontWeight='semibold'>Liability for links</Text>
        <Text spacing='m'>
          Our website contains links to external websites of third parties on whose contents we have no influence.
          Therefore, we cannot be held liable for these external contents. The respective provider or operator of the
          sites is always responsible for the contents of the linked pages. The linked pages were checked for possible
          legal infringements at the time of linking. Illegal contents were not recognizable at the time of linking.
          However, a permanent control of the contents of the linked sites is not reasonable without concrete evidence
          of a violation of law. As soon as we become aware of any legal infringements, we will remove such links
          immediately
        </Text>
        <Text fontWeight='semibold'>Copyright</Text>
        <Text>
          The contents and works on these pages created by the site operators are subject to German copyright law.
          Duplication, processing, distribution and any form of commercialization of such material beyond the scope of
          the copyright law shall require the prior written consent of its respective author or creator. Downloads and
          copies of these pages are only permitted for private, non-commercial use. Insofar as the content on this site
          was not created by the operator, the copyrights of third parties are respected. In particular, contents of
          third parties are marked as such. Should you nevertheless become aware of a copyright infringement, please
          inform us accordingly. As soon as we become aware of any infringements, we will remove such content
          immediately.
        </Text>
      </main>
      <footer className='m-auto w-full max-w-7xl'>
        <Footer />
      </footer>
    </div>
  );
};

export default ImpressumScreen;
