import React from 'react';

import { motion } from 'framer-motion';

const MotionEffectPopIn = ({ children }: { children: React.ReactElement }) => (
  <motion.div initial={{ opacity: 0, scale: 0.5 }} animate={{ opacity: 1, scale: 1 }} transition={{ duration: 0.2 }}>
    {children}
  </motion.div>
);

export default MotionEffectPopIn;
