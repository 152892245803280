import React from 'react';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { Button } from 'components/common/ComponentLibrary';
import { FormikInputField } from 'components/common/Formik';
import supabase from 'store/supabase/supabase';
import { useNavigate } from 'react-router-dom';
import { getUrlHome } from 'utilities/urls.utils';
import { trackEvent } from 'models/application/services/TrackingService';
import { TRACKING_EVENT_ENUM } from 'models/application/enums/trackingEventEnum';
import { LostPassword } from 'components/application/LostPassword';
import { notifyAboutError } from 'utilities/common/notifyAboutError.utils';

export const PASSWORD_MIN_LENGTH = 8;

const validationSchema = yup.object().shape({
  emailLogin: yup.string().email('Enter a valid email').trim().required('Enter your email'),
  password: yup
    .string()
    .trim()
    .min(PASSWORD_MIN_LENGTH, `Password should be at least ${PASSWORD_MIN_LENGTH} characters`)
    .required('Enter your password'),
});

const LoginForm = () => {
  const navigate = useNavigate();

  return (
    <Formik
      onSubmit={async ({ emailLogin, password }) => {
        const { error } = await supabase.auth.signInWithPassword({
          email: emailLogin.toLowerCase(),
          password,
        });
        if (error) {
          notifyAboutError(error);
          return;
        }

        trackEvent(TRACKING_EVENT_ENUM.SIGN_IN, {
          provider: 'email',
        });

        navigate(getUrlHome());
        window.location.reload();
      }}
      initialValues={{ emailLogin: '', password: '' }}
      validateOnBlur={true}
      validationSchema={validationSchema}
    >
      {({ isSubmitting, values }) => (
        <Form>
          <div className='space-y-5 w-full'>
            <FormikInputField type='email' name='emailLogin' placeholder='Enter your email address' autoFocus={true} />
            <div className='relative w-full'>
              <FormikInputField type='password' name='password' placeholder='Enter your password' />
              <div className='flex justify-end pt-2'>
                <LostPassword email={values.emailLogin} />
              </div>
            </div>
            <div className='flex justify-end'>
              <Button size='l' type='submit' status={isSubmitting ? 'busy' : ''} width='full'>
                Sign in
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default LoginForm;
