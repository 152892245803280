import React, { useRef } from 'react';
import Tag from './Tag';
import { useSearchStore } from 'store/search.store';
import { useDraggable } from 'react-use-draggable-scroll';

const Tags = () => {
  const [tagsStore, toggleTag] = useSearchStore((state) => [state.tags, state.toggleTag]);

  const ref = useRef<HTMLUListElement>() as React.MutableRefObject<HTMLUListElement>;
  const { events } = useDraggable(ref); // Now we pass the reference to the useDraggable hook:

  const handleTagClick = (tag: string) => {
    toggleTag(tag);
  };

  return (
    <ul
      className='max-w-full flex pb-3 select-none overflow-y-hidden overflow-x-auto dumpit-scrollbar gap-x-2 text-xs text-dark dark:text-bright'
      {...events}
      ref={ref}
    >
      {Object.entries(tagsStore).map((tag) => (
        <Tag key={tag[0]} tag={tag[0]} onClick={() => handleTagClick(tag[0])} state={tag[1]} />
      ))}
    </ul>
  );
};

export default Tags;
