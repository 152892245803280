import { DumpItIcon } from 'components/common/SpecialIcons';
import { Variants, motion } from 'framer-motion';
import React from 'react';

const dumpVariants: Variants = {
  hidden: {
    opacity: 0,
    y: 20,
  },
  visible: {
    opacity: 1,
    y: 0,
  },
  exit: () => ({
    transition: {
      type: 'tween',
      ease: 'easeOut',
      duration: 0.2,
    },
    scale: 1,
    opacity: 0,
    x: 0,
    y: -20,
  }),
};

const DumpCreatedNotification = () => (
  <motion.div
    variants={dumpVariants}
    initial='hidden'
    animate='visible'
    exit='exit'
    className='flex absolute w-32 h-32 m-auto top-0 bottom-0 right-0 left-0 items-center justify-center z-10'
    transition={{ type: 'tween', ease: 'easeInOut', duration: 0.2 }}
  >
    <DumpItIcon className='w-24' />
  </motion.div>
);

export default DumpCreatedNotification;
