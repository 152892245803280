import { IDump } from 'models/dump.model';
import { isDevEnv, isFeatEnv, isLocalhostEnv, isStagingEnv } from './env.utils';

export const SEARCH_PARAM = 'search';

export function getRootUrl(): string {
  if (isLocalhostEnv()) {
    return `http://localhost:3000`;
  }

  if (isFeatEnv()) {
    return `https://feat.dumpit.app`;
  }

  if (isDevEnv()) {
    return `https://dev.dumpit.app`;
  }

  if (isStagingEnv()) {
    return `https://staging.dumpit.app`;
  }

  return `https://dumpit.app`;
}

export function getUrlTerms(): string {
  return `/terms`;
}

export function getUrlPrivacy(): string {
  return `/privacy`;
}

export function getUrlCookiePolicy(): string {
  return `/cookies`;
}

export function getUrlImpressum(): string {
  return `/impressum`;
}

export function getUrlCookies(): string {
  return `/cookies`;
}

export function getUrlHome(): string {
  return `/`;
}

export function getUrlDumps(): string {
  return `/dumps`;
}

export function getUrlResetPassword(): string {
  return `/reset-password`;
}

export function getUrlIOSAppStore(): string {
  return `/@todo-phil`;
}

export function getUrlAndroidAppStore(): string {
  return `https://play.google.com/store/apps/details?id=app.dump.it`;
}

export function getUrlLostPassword(email?: string): string {
  return `/lost-password?email=${email}`;
}

export function getUrlMobileVerification(nonce: string, pubKey: string): string {
  const mobileIdentifierURL = process.env.MOBILE_IDENTIFIER_URL ?? 'app.dumpit.dev';
  return `${mobileIdentifierURL}://verify-device?n=${nonce}&d=${pubKey}`;
}

export function getUrlDump(dump: IDump): string {
  return `/dumps/${dump.id}`;
}

export function getUrlAccount(): string {
  return `/account`;
}

export function getUrlSettings(): string {
  return `/settings`;
}

export function getUrlVerifyEmail(email: string): string {
  return `/verify-email?email=${encodeURIComponent(email.toLowerCase())}`;
}
