import React from 'react';

type Props = React.ComponentPropsWithoutRef<'svg'>;

const AppleIcon: React.FC<Props> = (props) => (
  <svg
    height='560.035px'
    viewBox='0 0 456.008 560.035'
    version='1.1'
    xmlSpace='preserve'
    xmlns='http://www.w3.org/2000/svg'
    {...props} // Spread props to allow customization
  >
    <path d='M380.844,297.529c0.787,84.752,74.349,112.955,75.164,113.314c-0.622,1.988-11.754,40.191-38.756,79.652 c-23.343,34.117-47.568,68.107-85.731,68.811c-37.499,0.691-49.557-22.236-92.429-22.236c-42.859,0-56.256,21.533-91.753,22.928 c-36.837,1.395-64.889-36.891-88.424-70.883C10.822,419.585-25.931,292.64,23.419,206.95 c24.516-42.554,68.328-69.501,115.882-70.192c36.173-0.69,70.315,24.336,92.429,24.336c22.1,0,63.59-30.096,107.208-25.676 c18.26,0.76,69.517,7.376,102.429,55.552C438.715,192.614,380.208,226.674,380.844,297.529 M310.369,89.418 C329.926,65.745,343.089,32.79,339.498,0C311.308,1.133,277.22,18.785,257,42.445c-18.121,20.952-33.991,54.487-29.709,86.628 C258.712,131.504,290.811,113.106,310.369,89.418' />
  </svg>
);

export default AppleIcon;
