import {
  NODE_ENVIRONMENT_DEVELOPMENT,
  NODE_ENVIRONMENT_FEAT,
  NODE_ENVIRONMENT_LOCALHOST,
  NODE_ENVIRONMENT_PRODUCTION,
  NODE_ENVIRONMENT_STAGING,
} from 'constants/application.constants';

export type ENV_NAMES =
  | 'ENV'
  | 'SUPABASE_URL'
  | 'SUPABASE_ANON_KEY'
  | 'SUPABASE_PROJECT_URL'
  | 'MIXPANEL_PROJECT_TOKEN';

export const getEnvironmentVariable = (name: ENV_NAMES) => {
  const value = process.env[`REACT_APP_${name}`];
  if (!value) {
    throw new Error(`Environment variable 'REACT_APP_${name}' is not set.`);
  }
  return value;
};

export function isDevEnv(): boolean {
  return getEnvironmentVariable('ENV') === NODE_ENVIRONMENT_DEVELOPMENT;
}

export function isLocalhostEnv(): boolean {
  return getEnvironmentVariable('ENV') === NODE_ENVIRONMENT_LOCALHOST;
}

export function isStagingEnv(): boolean {
  return getEnvironmentVariable('ENV') === NODE_ENVIRONMENT_STAGING;
}

export function isFeatEnv(): boolean {
  return getEnvironmentVariable('ENV') === NODE_ENVIRONMENT_FEAT;
}

export function isProductionEnv(): boolean {
  return getEnvironmentVariable('ENV') === NODE_ENVIRONMENT_PRODUCTION;
}
