import React from 'react';
import ConfirmationDialog from 'components/common/dialog/ConfirmationDialog.component';
import BackdropComponent from 'components/common/dialog/Backdrop.component';
import { DumpInterface } from 'models/dumps/interfaces/DumpInterface';
import useDeleteDump from 'hooks/dumps/useDeleteDump';

interface IDeleteDumpModal {
  dump: DumpInterface;
  onDismiss?: () => void;
  onDeleted: () => void;
}

const DeleteDumpModal = ({ onDismiss, dump, onDeleted }: IDeleteDumpModal) => {
  const { deleteDump } = useDeleteDump(dump);

  return (
    <BackdropComponent
      dismiss={() => {
        onDismiss!();
      }}
    >
      <ConfirmationDialog
        title='Delete Dump?'
        leftItem={{ title: 'No', action: () => onDismiss!() }}
        rightItem={{
          title: 'Yes',
          action: async () => {
            await deleteDump();
            onDismiss!();
            onDeleted();
          },
          className: 'hover:bg-red/10 text-red',
        }}
      />
    </BackdropComponent>
  );
};

export default DeleteDumpModal;
