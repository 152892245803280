import { dumpitDatabase } from 'store/rxdb/dumpitDatabase';
import { getCurrentUTCDateFormatted } from 'utilities/date.utils';
import { getFileExtension } from 'utilities/common/file.utils';
import { AttachmentDocument } from 'store/rxdb/attachment/attachment.document';
import { AttachmentInterface } from 'models/attachments/interfaces/AttachmentInterface';

export function getAttachmentFilePath(
  attachment: { user_id: string; dump_id: string; id: string; filename: string } | AttachmentInterface,
  postfix = '',
) {
  return `${attachment.user_id}/${attachment.dump_id}/${attachment.id}${postfix}.${getFileExtension(
    attachment.filename,
  )}`;
}

export async function deleteAttachment(attachmentId: string) {
  try {
    const dumpDoc = await dumpitDatabase.attachments.findOne({ selector: { id: attachmentId } }).exec();
    if (!dumpDoc) return Promise.reject('No attachment found');

    await dumpDoc?.getLatest().update({
      $set: {
        is_synced: false,
        is_to_be_deleted: true,
        updated_at: getCurrentUTCDateFormatted(),
      },
    });

    return Promise.resolve();
  } catch (e) {
    return Promise.reject(e);
  }
}

export async function getAllAttachments(): Promise<AttachmentDocument[]> {
  try {
    return await dumpitDatabase.attachments.find().exec();
  } catch (e) {
    return Promise.reject(e);
  }
}

export async function getAttachmentsByDumpId(dumpId: string) {
  try {
    return await dumpitDatabase.attachments.queryAll(dumpId);
  } catch (e) {
    return Promise.reject(e);
  }
}

export async function getAllUnsyncedAttachments(): Promise<AttachmentDocument[] | null> {
  try {
    const attachments = await dumpitDatabase.attachments
      .find({
        selector: {
          is_synced: false,
        },
      })
      .exec();
    if (!attachments) {
      return null;
    }

    return attachments;
  } catch (e) {
    return null;
  }
}

export async function getAttachmentById(attachmentId: string): Promise<AttachmentDocument | null> {
  try {
    const attachment = await dumpitDatabase.attachments
      .findOne({
        selector: {
          id: attachmentId,
        },
      })
      .exec();
    if (!attachment) {
      return null;
    }

    return attachment;
  } catch (e) {
    return null;
  }
}

export async function markAttachmentSynced(attachmentId: string, additionalData?: Partial<AttachmentInterface>) {
  const existingAttachment = await getAttachmentById(attachmentId);
  if (existingAttachment) {
    await existingAttachment.getLatest().update({
      $set: {
        is_synced: true,
        ...additionalData,
      },
    });
  }
}

export async function upsertAttachment(attachment: Partial<AttachmentInterface>) {
  const existingAttachment = await getAttachmentById(attachment.id!);
  if (existingAttachment) {
    return await existingAttachment.getLatest().update({
      $set: attachment,
    });
  } else {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // @todo-phil I could not fix this TS issue :(
    return await dumpitDatabase.attachments.insert(attachment);
  }
}
