export const isString = (string: any) => typeof string === 'string';

export const capitalizeFirstLetter = (string?: string) => {
  if (!string) {
    return string;
  }

  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const shortenString = (string: string, length: number) =>
  string.length > length ? `${string.slice(0, length - 1)}...` : string;

export const getInitialsFromName = (name: string) => {
  const nameClean = name.replace(/[^a-zA-Z ]/g, '');
  const names = nameClean.trim().split(' ');
  let initials = names[0].substring(0, 1).toUpperCase();

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }

  return initials;
};

export const truncateString = (string: string, length: number, separator = '…') => {
  if (string.length <= length) return string;

  separator = separator || '…';

  const sepLen = separator.length;
  const charsToShow = length - sepLen;
  const frontChars = Math.ceil(charsToShow / 2);
  const backChars = Math.floor(charsToShow / 2);

  return string.substring(0, frontChars) + separator + string.substring(string.length - backChars);
};
