export enum TRACKING_EVENT_ENUM {
  SIGN_UP = 'signUp',
  SIGN_IN = 'signIn',
  SIGN_OUT = 'signOut',
  PAGE_VIEW = 'pageView',
  DUMP_CREATED = 'dumpCreated',
  DUMP_UPDATED = 'dumpUpdated',
  DUMP_DELETED = 'dumpDeleted',
  PASSWORD_RESET = 'passwordReset',
  ACCOUNT_DELETED = 'accountDeleted',
}
