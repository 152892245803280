import { mountStoreDevtool } from 'simple-zustand-devtools';
import { create } from 'zustand';
import { TAG_STATE } from '../constants/tag.constants';

export type ISearchStore = {
  selectedTag: string | null;
  tags: Record<string, TAG_STATE>;
  setSelectedTag: (selectedTags: string | null) => void;
  toggleTag: (tag: string) => void;
  setTags: (newTags: string[]) => void;
};

export const useSearchStore = create<ISearchStore>((set) => ({
  selectedTag: null,
  tags: {},
  setSelectedTag: (selectedTag) =>
    set(() => ({
      selectedTag,
    })),
  toggleTag: (tag: string) =>
    set((state) => {
      const currentStatus = state.tags[tag];

      let newState;
      if (currentStatus === TAG_STATE.SELECTED) {
        newState = TAG_STATE.UNSELECTED;
      } else if (currentStatus === TAG_STATE.UNSELECTED) {
        newState = TAG_STATE.DEFAULT;
      } else {
        newState = TAG_STATE.SELECTED;
      }

      return {
        tags: { ...state.tags, [tag]: newState },
      };
    }),
  setTags: (newTags) =>
    set((state) => {
      const tags = newTags.reduce(
        (acc, tag) => {
          acc[tag] = state.tags?.[tag] || TAG_STATE.DEFAULT;
          return acc;
        },
        {} as Record<string, TAG_STATE>,
      );
      return { tags };
    }),
}));

mountStoreDevtool('SearchStore', useSearchStore);
