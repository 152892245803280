import React, { useState } from 'react';
import { GlobeAltIcon } from '@heroicons/react/24/solid';
import { SignUpModal } from './SignUp';

const SignupForWeb = () => {
  const [isSignUpModalOpen, setIsSignUpModalOpen] = useState(false);
  return (
    <>
      <div
        className='flex w-52 h-16 bg-cyan text-white rounded-2xl items-center justify-start cursor-pointer'
        onClick={() => setIsSignUpModalOpen(true)}
      >
        <div className='mr-3 ml-4'>
          <GlobeAltIcon className='w-10' />
        </div>
        <div>
          <div className='text-xs font-semibold'>Use it on the</div>
          <div className='text-2xl font-semibold font-sans -mt-1'>Web</div>
        </div>
      </div>
      {isSignUpModalOpen && <SignUpModal onDismiss={() => setIsSignUpModalOpen(false)} />}
    </>
  );
};

export default SignupForWeb;
