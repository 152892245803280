import ContentLayout from './ContentLayout';
import Content from './subComponents/Content';
import Footer from './subComponents/Footer';
import Top from './subComponents/Top';

ContentLayout.Top = Top;
ContentLayout.Content = Content;
ContentLayout.Footer = Footer;

export { ContentLayout };
