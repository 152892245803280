import { ExtractDocumentTypeFromTypedRxJsonSchema, RxJsonSchema, toTypedRxJsonSchema } from 'rxdb';

export const attachmentSchemaLiteral = {
  title: 'dumpit attachment schema',
  version: 1,
  description: 'describes an attachment of a dump',
  primaryKey: 'id',
  type: 'object',
  properties: {
    id: {
      type: 'string',
      minLength: 36,
      maxLength: 36,
    },
    filename: {
      type: 'string',
    },
    dump_id: {
      ref: 'dumps',
      type: 'string',
    },
    user_id: {
      ref: 'profiles',
      type: 'string',
    },
    duration: {
      type: 'number',
    },
    created_at: {
      type: 'string',
    },
    updated_at: {
      type: 'string',
    },
    _deleted: {
      type: 'boolean',
    },
    is_to_be_deleted: {
      type: 'boolean',
      default: false,
    },
    is_synced: {
      type: 'boolean',
      default: false,
    },
    file_data: {
      type: 'string',
      contentEncoding: 'base64',
    },
  },
  required: ['id', 'filename', 'dump_id', 'user_id', 'created_at', 'updated_at'],
} as const;

const schemaTyped = toTypedRxJsonSchema(attachmentSchemaLiteral);
export type AttachmentDocType = ExtractDocumentTypeFromTypedRxJsonSchema<typeof schemaTyped>;
export const attachmentSchema: RxJsonSchema<AttachmentDocType> = attachmentSchemaLiteral;
