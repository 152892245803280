import { ReactNode } from 'react';
import { DumpInterface } from 'models/dumps/interfaces/DumpInterface';
import { pinDump } from 'models/dumps/services/DumpService';

interface IDumpPinAction {
  dump: DumpInterface;
  children: (props: { pinDump: () => void }) => ReactNode;
}

const DumpPinAction = ({ children, dump }: IDumpPinAction) => {
  const handlePinDump = async () => {
    await pinDump(dump.id, !dump.is_pinned);
  };

  return children?.({
    pinDump: handlePinDump,
  });
};

export default DumpPinAction;
