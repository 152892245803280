import React, { forwardRef } from 'react';
import FormikField from './FormikField';
import { Textarea } from '../ComponentLibrary';

interface IFormikTextareaField {
  name: string;
  label?: string;
  value?: any;
  placeholder: string;
  validate?: (value: string) => string | Promise<string> | undefined;
  onChange?: (e: string) => void;
}

const FormikTextareaField = forwardRef<any, IFormikTextareaField>(({ ...props }: IFormikTextareaField, ref) => {
  const { onChange: upstreamOnChange } = props;
  const renderInput = ({ field: { name: fieldName, value, onChange }, meta: { touched, error } }: any) => (
    <Textarea
      {...props}
      ref={ref}
      name={fieldName}
      value={value || ''}
      onChange={(_value: any) => {
        upstreamOnChange && upstreamOnChange(_value);
        onChange(_value);
      }}
      status={touched && error ? 'invalid' : ''}
    />
  );

  return <FormikField input={renderInput} {...props} />;
});

FormikTextareaField.displayName = 'FormikTextareaField';

export default FormikTextareaField;
