import { useEffect, useState } from 'react';
import { useVisibilityChange } from 'use-visibility-change';
import { captureException } from '@sentry/react';
import { pullLatestAttachments, pushUnsyncedAttachments } from 'models/attachments/services/AttachmentSyncService';

const useSyncAttachments = () => {
  const [isBusy, setIsBusy] = useState<boolean>(false);
  const isVisible = useVisibilityChange();

  useEffect(() => {
    if (isVisible) {
      (async () => {
        if (isBusy) {
          return;
        }

        setIsBusy(true);
        try {
          await pushUnsyncedAttachments();
          await pullLatestAttachments();
        } catch (error) {
          captureException(error);
        }
        setIsBusy(false);
      })();
    }
  }, [isVisible]);
};

export default useSyncAttachments;
