import DumpTagList from 'components/dumps/DumpTagList';
import { IDump } from 'models/dump.model';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { IRoute } from 'screens/routes';
import { getUrlDumps } from 'utilities/urls.utils';
import { useSearchStore } from 'store/search.store';

const DumpTags = (props: { dump: IDump }) => {
  const { dump } = props;
  const navigate = useNavigate();

  const toggleTag = useSearchStore((state) => state.toggleTag);
  return (
    <div className='flex items-center mb-2'>
      {dump?.tags && dump?.tags?.length > 0 && (
        <DumpTagList
          tags={dump.tags}
          searchTerm=''
          onToggleTag={(tag) => {
            toggleTag(tag);
            navigate(getUrlDumps(), {
              state: { previousRoute: 'dump_detail' as IRoute },
            });
          }}
        />
      )}
    </div>
  );
};

export default DumpTags;
