import React, { Children } from 'react';

interface ChildType {
  role?: string;
}

export default function findChildrenByRole(children: React.ReactNode, role: string, limit: number | null = null) {
  const childrenArray = Children.toArray(children);

  const filteredChildren = childrenArray.filter(
    (child: React.ReactNode) =>
      (child as unknown as { type?: string })?.type &&
      ((child as unknown as { type?: string }).type as ChildType).role === role,
  );
  if (!filteredChildren.length) {
    return null;
  }

  return limit ? filteredChildren.slice(0, limit) : filteredChildren;
}
