import { SupabaseClient } from '@supabase/supabase-js';
import { FeedbackInterface } from 'models/feedback/interfaces/FeedbackInterface';

const TABLE_NAME_FEEDBACK = 'feedback';

export class SupabaseFeedbackService {
  supabaseClient: SupabaseClient;

  constructor(_supabaseClient: SupabaseClient) {
    this.supabaseClient = _supabaseClient;
  }

  async create(description: string, userId: string): Promise<FeedbackInterface> {
    const { data, error } = await this.supabaseClient
      .from(TABLE_NAME_FEEDBACK)
      .insert({
        description,
        user_id: userId,
        platform: 'web',
      })
      .select()
      .single();
    if (error) {
      throw new Error(error.message);
    }

    return data;
  }
}
