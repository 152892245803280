import { KeyboardEvent } from 'react';
import { MentionItem } from 'react-mentions';

interface UseTabIndentParams {
  value?: string;
  onChange: (
    event: { target: { value: string } },
    newValue: string,
    newPlainTextValue: string,
    mentions: MentionItem[],
  ) => void;
}

export const useTabIndent = ({ value = '', onChange }: UseTabIndentParams) => {
  const handleKeyDown = (e: KeyboardEvent<HTMLTextAreaElement> | KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Tab' && !e.shiftKey) {
      e.preventDefault();

      const target = e.target as HTMLTextAreaElement;
      const start = target.selectionStart ?? 0;
      const end = target.selectionEnd ?? 0;

      // If there's selected text, handle multiple lines
      if (start !== end) {
        const selectedText = value.substring(start, end);
        const lines = selectedText.split('\n');

        // Add tab to the start of each line
        const newText = lines.map((line) => '\t' + line).join('\n');
        const newValue = value.substring(0, start) + newText + value.substring(end);

        onChange({ target: { value: newValue } }, newValue, newValue, []);

        // Maintain selection
        requestAnimationFrame(() => {
          target.selectionStart = start;
          target.selectionEnd = start + newText.length;
        });
      } else {
        // Single line/cursor case
        const newValue = value.substring(0, start) + '\t' + value.substring(end);

        onChange({ target: { value: newValue } }, newValue, newValue, []);

        // Move cursor after the tab
        requestAnimationFrame(() => {
          target.selectionStart = target.selectionEnd = start + 1;
        });
      }
    }
  };

  return handleKeyDown;
};
