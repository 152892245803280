import GlobalSearch from 'components/search/GlobalSearch.component';
import useTheme from 'hooks/application/useTheme';
import React, { Suspense, useEffect, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useLocation } from 'react-router-dom';
import AnimatedRouter from 'screens/AnimatedRouter.component';
import { useAuthStore } from 'store/auth.store';
import { useSettingsStore } from 'store/setting.store';
import { getUrlDumps } from 'utilities/urls.utils';
import KeyboardShortcutsModal from './KeyboardShortcutsModal';
import { KeyboardIcon } from 'components/common/SpecialIcons';
import LoadingScreenIndicator from 'components/common/LoadingScreenIndicator';

const App = () => {
  const location = useLocation();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [showKeyboardShortcutsModal, setShowKeyboardShortcutsModal] = useState<boolean>(false);

  const [currentUser] = useAuthStore((state) => [state.currentUser]);

  useTheme();

  const [isSearchVisible, setIsSearchVisible] = useSettingsStore((state) => [
    state.isGlobalSearchVisible,
    state.setGlobalSearchVisibility,
  ]);

  useHotkeys('esc', () => setIsSearchVisible(false), [], {
    enableOnFormTags: true,
  });

  useHotkeys(
    'meta+k',
    (event) => {
      event.preventDefault();
      setIsSearchVisible(!isSearchVisible);
    },
    [isSearchVisible],
    {
      enableOnFormTags: true,
    },
  );

  useHotkeys(
    'meta+/',
    (event) => {
      event.preventDefault();
      setShowKeyboardShortcutsModal(!showKeyboardShortcutsModal);
    },
    [showKeyboardShortcutsModal],
    {
      enableOnFormTags: true,
      preventDefault: true,
    },
  );

  useEffect(() => {
    setIsSearchVisible(false);
    setSearchTerm('');
  }, [location]);

  return (
    <Suspense fallback={<LoadingScreenIndicator />}>
      <AnimatedRouter />

      {isSearchVisible && location.pathname !== getUrlDumps() && (
        <GlobalSearch dismiss={() => setIsSearchVisible(false)} searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
      )}
      {showKeyboardShortcutsModal && <KeyboardShortcutsModal onDismiss={() => setShowKeyboardShortcutsModal(false)} />}

      {currentUser && (
        <div
          className='rounded-2xl p-1 fixed hidden md:block right-8 bottom-5 cursor-pointer'
          onClick={() => setShowKeyboardShortcutsModal(!showKeyboardShortcutsModal)}
        >
          <KeyboardIcon className='w-12 dark:text-zinc-600 text-zinc-400 hover:text-zinc-500 dark:hover:text-zinc-500' />
        </div>
      )}
    </Suspense>
  );
};

export default App;
