import React from 'react';
import Item from './subcomponents/Item';

interface IMenu {
  children: React.ReactNode;
}

const Menu = ({ children }: IMenu) => (
  <div className='grid grid-cols-1 divide-y divide-dark/25 dark:divide-white/25 min-w-[140px] w-full'>{children}</div>
);

Menu.displayName = 'Menu';

export default Object.assign(Menu, { Item });
