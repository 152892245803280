import { HighlightedText } from 'components/common/HighlightedText.util';
import { IDump } from 'models/dump.model';
import React from 'react';
import { Text } from 'components/common/ComponentLibrary';
import { isFilePathAudio, isFilePathGeneric, isFilePathImage } from 'utilities/storage.utils';
import { STORAGE_BUCKET_ENUM } from 'models/application/enums/ImageEnums';
import DumpRowImagePreview from './DumpRowImagePreview';
import DumpRowAudioPreview from './DumpRowAudioPreview';
import { AttachmentInterface } from 'models/attachments/interfaces/AttachmentInterface';
import { useSettingsStore } from 'store/setting.store';
import { DENSITY } from 'constants/density.constants';
import SupabaseImageAttachment from 'components/common/SupabaseImageAttachment';
import SupabaseAttachment from 'components/common/SupabaseAttachment';
import { getAttachmentFilePath } from 'models/attachments/services/AttachmentService';
import DumpRowGenericPreview from './DumpRowGenericPreview';

const getLineClamp: any = (density: DENSITY) => {
  if (density === DENSITY.ALL) {
    return 'none';
  }
  if (density === DENSITY.COMPACT) {
    return 2;
  }

  return 6;
};

const DumpRowContents = (props: { searchTerm: string; dump: IDump; attachments?: AttachmentInterface[] | null }) => {
  const { dump, attachments, searchTerm } = props;

  const [settings] = useSettingsStore((state) => [state.settings]);

  return (
    <div>
      {attachments && attachments.length > 0 && !dump.text && (
        <div className='flex items-center gap-2'>
          {attachments.map((attachment) => {
            if (isFilePathImage(attachment.filename)) {
              return (
                <SupabaseImageAttachment
                  key={attachment.id}
                  path={getAttachmentFilePath(
                    attachment.user_id,
                    attachment.dump_id,
                    attachment.id,
                    attachment.filename,
                  )}
                  storageBucket={STORAGE_BUCKET_ENUM.ATTACHMENTS}
                >
                  {({ previewSrc, src }) => <DumpRowImagePreview previewSrc={previewSrc || src} />}
                </SupabaseImageAttachment>
              );
            }

            if (isFilePathGeneric(attachment.filename)) {
              return (
                <SupabaseAttachment
                  key={attachment.id}
                  path={getAttachmentFilePath(
                    attachment.user_id,
                    attachment.dump_id,
                    attachment.id,
                    attachment.filename,
                  )}
                  storageBucket={STORAGE_BUCKET_ENUM.ATTACHMENTS}
                >
                  {({ src }) => <DumpRowGenericPreview filename={attachment.filename} />}
                </SupabaseAttachment>
              );
            }

            if (isFilePathAudio(attachment.filename)) {
              return <DumpRowAudioPreview key={attachment.id} />;
            }

            return null;
          })}
        </div>
      )}
      {dump.text && (
        <div className='w-full whitespace-pre-wrap break-all'>
          {searchTerm.length > 0 ? (
            <HighlightedText text={dump.text} highlight={searchTerm} />
          ) : (
            <Text lineClamp={getLineClamp(settings?.density)}>{dump.text}</Text>
          )}
        </div>
      )}
    </div>
  );
};

export default DumpRowContents;
