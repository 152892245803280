import { CurrentUser } from 'models/current-user.model';
import { mountStoreDevtool } from 'simple-zustand-devtools';
import { create } from 'zustand';
import { Session } from '@supabase/supabase-js';

export type IAuthStore = {
  isAuthenticated: boolean;
  currentUser: CurrentUser | null;
  session: Session | null;
  setCurrentUser: (user: CurrentUser, session: Session) => void;
};

export const useAuthStore = create<IAuthStore>((set) => ({
  isAuthenticated: false,
  currentUser: null,
  session: null,
  setCurrentUser: (user, session) =>
    set((state) => ({
      ...state,
      isAuthenticated: true,
      currentUser: user,
      session,
    })),
}));

mountStoreDevtool('AuthStore', useAuthStore);
