import supabase from 'store/supabase/supabase';
import { Session } from '@supabase/supabase-js';
import { mixpanelReset, trackEvent } from './TrackingService';
import { dumpitDatabase } from 'store/rxdb/dumpitDatabase';
import { TRACKING_EVENT_ENUM } from '../enums/trackingEventEnum';
import { clearLocalStorageItems } from './LocalStorageService';

export async function getSession(): Promise<Session | null> {
  const {
    data: { session: authSession },
  } = await supabase.auth.getSession();

  return authSession;
}

export async function logout(): Promise<void> {
  await supabase.auth.signOut();

  await dumpitDatabase.remove();

  clearLocalStorageItems();

  trackEvent(TRACKING_EVENT_ENUM.SIGN_OUT);
  mixpanelReset();
}
