export enum PLAYSPEED {
  SLOWEST = 0.25,
  SLOWER = 0.5,
  SLOW = 0.75,
  NORMAL = 1.0,
  FAST = 1.25,
  FASTER = 1.5,
  EVEN_FASTER = 1.75,
  FASTEST = 2.0,
}
