import React from 'react';
import Footer from 'components/application/Footer';
import useSignupErrorHandler from 'hooks/application/useSignupErrorHandler';
import {
  LandingHeader,
  LandingFeatures,
  LandingMain,
  LandingBottomPromo,
  LandingMocks,
} from 'components/application/Landing';
import StickyHeader from 'components/common/StickyHeader';
import './landing.css';

const LandingScreen = () => {
  useSignupErrorHandler();

  return (
    <div className='m-auto flex min-h-screen flex-col relative' id='landing'>
      <StickyHeader>
        <header className='flex items-center justify-between m-auto w-full max-w-7xl py-5'>
          <LandingHeader />
        </header>
      </StickyHeader>

      <main className='m-auto flex flex-col items-center justify-center md:mt-52 mb-20 md:mb-28'>
        <LandingMain />
      </main>

      <section className='flex items-center justify-center'>
        <LandingFeatures />
      </section>

      <section className='flex items-center justify-center mt-10 md:mt-24 md:mb-56 mb-0'>
        <LandingMocks />
      </section>

      <section className='flex items-center justify-center mt-24 mb-20'>
        <LandingBottomPromo />
      </section>

      <footer className='m-auto w-full max-w-7xl'>
        <Footer />
      </footer>
    </div>
  );
};

export default LandingScreen;
