import { RxDatabase, addRxPlugin, createRxDatabase, removeRxDatabase } from 'rxdb';
import { RxDBDevModePlugin } from 'rxdb/plugins/dev-mode';
import { getRxStorageDexie } from 'rxdb/plugins/storage-dexie';
import { AttachmentCollection, defaultAttachmentCollectionMethods } from './attachment/attachment.collection';
import { attachmentSchema } from './attachment/attachment.schema';
import { RxDBMigrationPlugin } from 'rxdb/plugins/migration-schema';
import { RxDBUpdatePlugin } from 'rxdb/plugins/update';
import { RxDBQueryBuilderPlugin } from 'rxdb/plugins/query-builder';
import { defaultAttachmentDocumentMethods } from './attachment/attachment.document';
import { DumpCollection, defaultDumpCollectionsMethods } from './dump/dump.collection';
import { defaultDumpDocumentMethods } from './dump/dump.document';
import { dumpSchema } from './dump/dump.schema';
import { DATABASE_NAME_INDEX_DB } from 'constants/application.constants';
import { tagSchema } from './tag/tag.schema';
import { defaultTagDocumentMethods } from './tag/tag.document';
import { defaultTagCollectionMethods, TagCollection } from './tag/tag.collection';
import { dumpChangesListener } from './dumpChangesListener';
import { clearLocalStorageItems } from 'models/application/services/LocalStorageService';
import { captureException } from '@sentry/react';

addRxPlugin(RxDBDevModePlugin);
addRxPlugin(RxDBMigrationPlugin);
addRxPlugin(RxDBUpdatePlugin);
addRxPlugin(RxDBQueryBuilderPlugin);

export type DumpitCollections = {
  dumps: DumpCollection;
  attachments: AttachmentCollection;
  tags: TagCollection;
};
export type IDumpitDatabase = RxDatabase<DumpitCollections>;

export const dumpitDatabase: IDumpitDatabase = await (async () => {
  async function createDatabase() {
    const db = await createRxDatabase<IDumpitDatabase>({
      name: DATABASE_NAME_INDEX_DB,
      storage: getRxStorageDexie(),
      multiInstance: false,
      ignoreDuplicate: true,
    });

    const collections = await db.addCollections({
      dumps: {
        schema: dumpSchema,
        methods: defaultDumpDocumentMethods,
        statics: defaultDumpCollectionsMethods,
        migrationStrategies: {
          1: (oldDoc) => {
            return oldDoc;
          },
        },
      },
      tags: {
        schema: tagSchema,
        methods: defaultTagDocumentMethods,
        statics: defaultTagCollectionMethods,
        migrationStrategies: {
          1: (oldDoc) => {
            return oldDoc;
          },
        },
      },
      attachments: {
        schema: attachmentSchema,
        methods: defaultAttachmentDocumentMethods,
        statics: defaultAttachmentCollectionMethods,
        migrationStrategies: {
          1: (oldDoc) => {
            return oldDoc;
          },
        },
      },
    });

    collections.dumps.$.subscribe((change: any) => {
      dumpChangesListener(change);
    });

    return db;
  }

  try {
    return await createDatabase();
  } catch (error: any) {
    if (
      error.message.includes('another instance created this collection with a different schema') ||
      error.message.includes('Internal error opening backing store') ||
      error.message.includes('Cannot open database state with newer RxDB')
    ) {
      await removeRxDatabase(DATABASE_NAME_INDEX_DB, getRxStorageDexie());

      clearLocalStorageItems();

      indexedDB.deleteDatabase(DATABASE_NAME_INDEX_DB);

      await new Promise((resolve) => setTimeout(resolve, 100));

      return await createDatabase();
    } else {
      captureException(error);
      throw error;
    }
  }
})();
