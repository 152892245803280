import React from 'react';
import { useSettingsStore } from 'store/setting.store';

type ItemProps = {
  title: string;
  action: () => void;
  className?: string;
};

type Props = {
  title: string;
  leftItem: ItemProps;
  rightItem: ItemProps;
};

const ConfirmationDialog: React.FC<Props> = ({ title, leftItem, rightItem }) => {
  const isSearchVisible = useSettingsStore((state) => state.isGlobalSearchVisible);
  return (
    <div className='flex flex-col justify-between select-none'>
      <h1 className='py-4 px-12'>{title}</h1>
      <div className='flex w-full border-t-1 border-dark/10 dark:border-white/10'>
        <button
          onClick={leftItem.action}
          disabled={isSearchVisible}
          className={`whitespace-nowrap hover:rounded-bl-2xl ${
            leftItem.className ?? 'hover:bg-dark/10 dark:hover:bg-white/10 dark:text-white text-dark'
          } flex-1 px-4 py-2 border-r-1 border-dark/10 dark:border-white/10`}
        >
          {leftItem.title}
        </button>
        <button
          disabled={isSearchVisible}
          onClick={rightItem.action}
          className={`flex-1 px-4 py-2 hover:rounded-br-2xl whitespace-nowrap ${
            rightItem.className ?? 'hover:bg-dark/10 dark:hover:bg-white/10 dark:text-white text-dark'
          }`}
        >
          {rightItem.title}
        </button>
      </div>
    </div>
  );
};

export default ConfirmationDialog;
