import React from 'react';
import { Heading, Hyperlink } from 'components/common/ComponentLibrary';
import { getUrlHome } from 'utilities/urls.utils';
import { DumpItIcon } from 'components/common/SpecialIcons';
import Footer from 'components/application/Footer';
import { ResetPasswordForm } from 'components/application/ResetPassword';
import useDocumentTitle from 'hooks/application/useDocumentTitle';

const ResetPasswordPage = () => {
  useDocumentTitle('Change password');

  return (
    <div className='m-auto flex min-h-screen flex-col'>
      <div className='flex items-center text-center justify-center mt-5 mb-10 md:mb-32'>
        <Hyperlink href={getUrlHome()}>
          <DumpItIcon className='w-24' />
        </Hyperlink>
      </div>
      <div className='m-auto max-w-sm px-4 md:px-0 flex-1'>
        <div className='space-y-4'>
          <Heading size='xl' textAlign='center' fontWeight='bold'>
            Change password
          </Heading>
          <ResetPasswordForm />
        </div>
      </div>
      <footer className='m-auto w-full max-w-7xl'>
        <Footer />
      </footer>
    </div>
  );
};

export default ResetPasswordPage;
