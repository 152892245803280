import React from 'react';
import findChildrenByRole from './findChildrenByRole';

export default function findChildByRole(children: React.ReactNode, role: string) {
  const nodes = findChildrenByRole(children, role, 1);
  if (!nodes || nodes.length === 0) {
    return null;
  }

  return nodes[0];
}
