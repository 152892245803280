import React, { ReactNode } from 'react';
import { DumpInterface } from 'models/dumps/interfaces/DumpInterface';
import { mountModal } from 'utilities/common/modals.utils';
import DeleteDumpModal from './DeleteDumpModal';
import { useNavigate } from 'react-router-dom';
import { getUrlDumps } from 'utilities/urls.utils';

interface IDumpDeleteAction {
  dump: DumpInterface;
  children: (props: { deleteDump: () => void }) => ReactNode;
}

const DumpDeleteAction = ({ children, dump }: IDumpDeleteAction) => {
  const navigate = useNavigate();
  return (
    <>
      {children?.({
        deleteDump: () => {
          mountModal(<DeleteDumpModal dump={dump} onDeleted={() => navigate(getUrlDumps())} />);
        },
      })}
    </>
  );
};

export default DumpDeleteAction;
