import { RxCollection } from 'rxdb';
import { TagDocument, TagDocumentMethods } from './tag.document';
import { TagDocumentType } from './tag.schema';

export type TagCollectionMethods = {
  queryAll: () => Promise<TagDocument[]>;
};

export type TagCollection = RxCollection<TagDocumentType, TagDocumentMethods, TagCollectionMethods>;

export const defaultTagCollectionMethods: TagCollectionMethods = {
  queryAll: async function (this: TagCollection) {
    return this.find().exec();
  },
};
