import React from 'react';
import FormikField from './FormikField';
import { useFormikContext } from 'formik';
import { DumpTextarea } from 'components/common/DumpTextarea';

interface IFormikDumpTextareaField {
  name: string;
  label?: string;
  placeholder: string;
  validate?: (value: string) => string | Promise<string> | undefined;
  onChange?: (e: string) => void;
}

const FormikDumpTextareaField: React.FC<IFormikDumpTextareaField> = (props) => {
  const { setFieldValue } = useFormikContext();
  const { name, label: upstreamLabel, validate, onChange: upstreamOnChange, ...upstreamProps } = props;

  const label = typeof upstreamLabel !== 'string' ? '' : upstreamLabel;

  const renderInput = ({
    field: { name: fieldName, value },
  }: {
    field: {
      name: string;
      value: string;
    };
    meta: { touched: boolean; error?: string };
  }) => (
    <DumpTextarea
      name={fieldName}
      value={value}
      {...upstreamProps}
      onChange={(e, newValue, newPlainTextValue: any) => {
        upstreamOnChange && upstreamOnChange(newPlainTextValue);
        setFieldValue(fieldName, newPlainTextValue);
      }}
    />
  );

  return <FormikField name={name} input={renderInput} label={label} validate={validate} />;
};

export default FormikDumpTextareaField;
