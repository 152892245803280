import React from 'react';
import BackdropComponent from 'components/common/dialog/Backdrop.component';
import FeedbackForm from './FeedbackForm';
import { Heading, Text } from 'components/common/ComponentLibrary';
import { useTranslation } from 'react-i18next';

const FeedbackModal = ({ onDismiss }: { onDismiss: () => void }) => {
  const { t } = useTranslation('translations');

  return (
    <BackdropComponent dismiss={onDismiss}>
      <div className='w-full p-6 md:max-w-[400px]'>
        <Heading size='xl' textAlign='left' spacing='s'>
          {t('feedbackModal.title')}
        </Heading>
        <Text spacing='xl'>{t('feedbackModal.description')}</Text>
        <FeedbackForm onDone={onDismiss} />
      </div>
    </BackdropComponent>
  );
};

export default FeedbackModal;
