'use client';

import React from 'react';
import { Formik, Form } from 'formik';
import * as yup from 'yup';

import supabase from 'store/supabase/supabase';
import { getRootUrl, getUrlResetPassword } from 'utilities/urls.utils';
import { Button } from 'components/common/ComponentLibrary';
import { FormikInputField } from 'components/common/Formik';
import { useSearchParams } from 'react-router-dom';

const validationSchema = yup.object().shape({
  email: yup.string().email('Enter a valid email').trim().required('Enter your email'),
});

const LostPasswordForm = ({ onReset }: { onReset: () => void }) => {
  const [searchParams] = useSearchParams();

  const onSubmit = async ({ email }: { email: any }, { resetForm }: { resetForm: () => void }) => {
    await supabase.auth.resetPasswordForEmail(email, {
      redirectTo: `${getRootUrl()}${getUrlResetPassword()}`,
    });

    resetForm();
    onReset();
  };

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={{ email: searchParams.get('email') || '' }}
      validateOnBlur={true}
      validationSchema={validationSchema}
    >
      {({ isSubmitting }) => (
        <Form>
          <FormikInputField type='email' name='email' placeholder='email@example.com' />
          <div className='flex justify-end pt-4'>
            <Button size='m' width='full' type='submit' status={isSubmitting ? 'busy' : ''}>
              Change password
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default LostPasswordForm;
