import React from 'react';
import BackdropComponent from 'components/common/dialog/Backdrop.component';
import { AudioPlayer } from 'components/common/AudioPlayer';

const AttachmentAudioModal = ({ onDismiss, src }: { onDismiss: () => void; src: string }) => (
  <BackdropComponent dismiss={onDismiss} theme='plain'>
    <AudioPlayer src={src} />
  </BackdropComponent>
);

export default AttachmentAudioModal;
