import React from 'react';
import BackdropComponent from 'components/common/dialog/Backdrop.component';
import SignUp from './SignUp';
import { Heading } from 'components/common/ComponentLibrary';

const SignUpModal = ({ onDismiss, email }: { onDismiss: () => void; email?: string }) => (
  <BackdropComponent dismiss={onDismiss}>
    <div className='md:px-20 p-5 md:py-10'>
      <div className='flex flex-col space-y-7 md:w-[500px]'>
        <Heading size='xxl'>Sign up</Heading>
        <SignUp email={email} showLoginAction={false} />
      </div>
    </div>
  </BackdropComponent>
);

export default SignUpModal;
