import React, { ReactNode } from 'react';
import { toast } from 'react-toastify';
import { IDump } from 'models/dump.model';
import { DocumentDuplicateIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';

interface IDumpCopyAction {
  dump: IDump;
  children: (props: { copyDump: () => void }) => ReactNode;
}

const DumpCopyAction = ({ children, dump }: IDumpCopyAction) => {
  const { t } = useTranslation('translations');

  const handleCopyDump = async () => {
    await navigator.clipboard.writeText(dump.text ?? '');
    toast.info(t('toaster.copiedToClipboard'), {
      autoClose: 2000,
      icon: <DocumentDuplicateIcon className='w-5' />,
    });
  };

  return children?.({
    copyDump: handleCopyDump,
  });
};

export default DumpCopyAction;
