import { mountStoreDevtool } from 'simple-zustand-devtools';
import { create } from 'zustand';
import { SupabaseProfileService } from 'models/profiles/services/supabase/SupabaseProfileService';
import { ProfileInterface } from 'models/profiles/interfaces/ProfileInterface';
import supabase from './supabase/supabase';
import { captureException } from '@sentry/react';

export type IProfile = {
  profile: ProfileInterface | null;
  getProfile: () => Promise<ProfileInterface | null>;
  setProfile: (profile: ProfileInterface | null) => void;
  updateProfile: (data: Partial<ProfileInterface>) => Promise<void>;
};

export const useProfileStore = create<IProfile>((set, get) => ({
  profile: null,

  getProfile: async () => {
    try {
      const supabaseProfileService = new SupabaseProfileService(supabase);

      const gotProfile = await supabaseProfileService.getCurrentProfile();
      if (!gotProfile) {
        return null;
      }

      set((state) => ({
        ...state,
        profile: gotProfile,
      }));

      return gotProfile;
    } catch (e) {
      captureException(e);
      return null;
    }
  },

  setProfile: (profile) =>
    set((state) => ({
      ...state,
      profile,
    })),

  updateProfile: async (profile: Partial<ProfileInterface>) => {
    let currentProfile = get().profile;
    if (!currentProfile || !currentProfile.id) {
      currentProfile = await get().getProfile();
    }
    if (!currentProfile) {
      throw new Error('Profile not found');
    }

    try {
      const supabaseProfileService = new SupabaseProfileService(supabase);
      const updatedProfile = await supabaseProfileService.updateProfile(currentProfile.id, profile);

      set((state) => ({
        ...state,
        profile: updatedProfile,
      }));
    } catch (e) {
      captureException(e);
    }
  },
}));

mountStoreDevtool('ProfileStore', useProfileStore);
