import React from 'react';
import BackdropComponent from 'components/common/dialog/Backdrop.component';
import { GoogleIcon } from 'components/common/SpecialIcons';
import { AppleIcon } from 'components/common/SpecialIcons';
import LoginForm from './LoginForm';
import DividerText from 'components/common/DividerText';
import { Button, Heading } from 'components/common/ComponentLibrary';
import supabase, { PROVIDER_APPLE, PROVIDER_GOOGLE } from 'store/supabase/supabase';
import { TRACKING_EVENT_ENUM } from 'models/application/enums/trackingEventEnum';
import { trackEvent } from 'models/application/services/TrackingService';
import { Provider } from '@supabase/supabase-js';
import { isIOS, isMobileSafari, isSafari } from 'react-device-detect';

const LoginModal = ({ onDismiss }: { onDismiss: () => void }) => {
  const handleAuthenticateWith = (provider: Provider) => {
    supabase.auth.signInWithOAuth({
      provider,
    });

    trackEvent(TRACKING_EVENT_ENUM.SIGN_IN, {
      provider,
    });
  };

  return (
    <BackdropComponent dismiss={onDismiss}>
      <div className='md:px-20 p-5 md:py-10'>
        <div className='flex flex-col space-y-7 md:w-96'>
          <Heading size='xxl'>Sign in</Heading>

          <LoginForm />

          <DividerText>or</DividerText>

          <div className='flex flex-col gap-5'>
            <Button size='l' color='white' onClick={() => handleAuthenticateWith(PROVIDER_GOOGLE)}>
              <Button.Icon icon={<GoogleIcon className='h-7 aspect-square' />} />
              Sign in with Google
            </Button>
            {(isIOS || isMobileSafari || isSafari) && (
              <Button size='l' color='white' onClick={() => handleAuthenticateWith(PROVIDER_APPLE)}>
                <Button.Icon icon={<AppleIcon className='h-7 aspect-square' />} />
                Sign in with Apple
              </Button>
            )}
          </div>
        </div>
      </div>
    </BackdropComponent>
  );
};

export default LoginModal;
