import { RxCollection } from 'rxdb';
import { AttachmentDocument, AttachmentDocumentMethods } from './attachment.document';
import { AttachmentDocType } from './attachment.schema';

export type AttachmentCollectionMethods = {
  queryAll: (dumpId: string) => Promise<AttachmentDocument[]>;
};

export type AttachmentCollection = RxCollection<
  AttachmentDocType,
  AttachmentDocumentMethods,
  AttachmentCollectionMethods
>;

export const defaultAttachmentCollectionMethods: AttachmentCollectionMethods = {
  queryAll: async function (this: AttachmentCollection, dumpId: string) {
    return this.find({
      selector: {
        dump_id: dumpId,
      },
    })
      .sort('created_at')
      .exec();
  },
};
