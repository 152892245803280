import { pullLatestDumps, pushUnsyncedDumps } from 'models/dumps/services/DumpSyncService';
import { useEffect, useState } from 'react';
import { useVisibilityChange } from 'use-visibility-change';
import { captureException } from '@sentry/react';

const useSyncDumps = () => {
  const [isBusy, setIsBusy] = useState<boolean>(false);
  const isVisible = useVisibilityChange();

  useEffect(() => {
    if (isVisible) {
      (async () => {
        if (isBusy) {
          return;
        }

        setIsBusy(true);
        try {
          await pushUnsyncedDumps();
          await pullLatestDumps();
        } catch (error) {
          captureException(error);
        }
        setIsBusy(false);
      })();
    }
  }, [isVisible]);
};

export default useSyncDumps;
