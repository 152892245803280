import React from 'react';
import { MentionsInput, Mention, OnChangeHandlerFunc } from 'react-mentions';
import { Text } from 'components/common/ComponentLibrary';
import useTags from 'hooks/tags/useTags';
import { useTabIndent } from 'hooks/common/useTabIndent';
import { isMobile } from 'react-device-detect';
import './DumpTextarea.css';

interface IDumpTextarea {
  value?: string;
  name: string;
  placeholder?: string;
  onChange: OnChangeHandlerFunc;
  textareaRef?: React.MutableRefObject<HTMLTextAreaElement | null>;
}

const DumpTextarea = ({ placeholder = '', textareaRef, onChange, value = '', name }: IDumpTextarea) => {
  const { tags } = useTags();
  const handleKeyDown = useTabIndent({ value, onChange });

  return (
    <div className='grow outline-none resize-none bg-transparent tracking-wide w-full h-full'>
      <MentionsInput
        autoFocus={!isMobile}
        className='mentions'
        id={name}
        name={name}
        placeholder={placeholder}
        aria-multiline={true}
        onChange={onChange}
        value={value}
        onKeyDown={handleKeyDown}
        inputRef={(e: any) => {
          if (textareaRef) {
            textareaRef.current = e;
          }
        }}
        customSuggestionsContainer={(children) => {
          return (
            <div className='relative max-h-60 overflow-y-auto rounded-xl max-w-xxs shadow-xl bg-bright text-dark dark:text-white dark:bg-paperdark border-1 border-dark/25 dark:border-white/25'>
              {children}
            </div>
          );
        }}
      >
        <Mention
          style=''
          className='mentions__mention'
          appendSpaceOnAdd={true}
          trigger='#'
          data={(query) => {
            return tags
              ? tags
                  ?.map((tag) => ({ id: tag, display: tag }))
                  .filter((tag) => tag.display.includes(query))
                  .slice(0, 5)
              : [];
          }}
          displayTransform={(tag) => `#${tag}`}
          renderSuggestion={(suggestion, search, highlightedDisplay) => (
            <div className='py-2 px-4'>
              <Text>#{highlightedDisplay}</Text>
            </div>
          )}
        />
      </MentionsInput>
    </div>
  );
};

export default DumpTextarea;
