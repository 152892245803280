import { RxDocument } from 'rxdb';
import { AttachmentDocType } from './attachment.schema';
import { AttachmentInterface } from 'models/attachments/interfaces/AttachmentInterface';

export type AttachmentDocumentMethods = {
  asAttachment: () => AttachmentInterface;
};

export type AttachmentDocument = RxDocument<AttachmentDocType, AttachmentDocumentMethods>;

export const defaultAttachmentDocumentMethods: AttachmentDocumentMethods = {
  asAttachment: function (this: AttachmentDocument) {
    return {
      id: this.id,
      filename: this.filename,
      duration: this.duration,
      dump_id: this.dump_id,
      user_id: this.user_id,
      created_at: this.created_at,
      updated_at: this.updated_at,
      _deleted: this._deleted,
      is_synced: this.is_synced,
      file_data: this.file_data,
      is_to_be_deleted: this.is_to_be_deleted,
    };
  },
};
