import { useEffect, useState } from 'react';
import { TagDocument } from 'store/rxdb/tag/tag.document';
import { getAllTagsObservable } from 'models/tags/services/rxDb/RxDbTagService';
import { sortTags } from 'utilities/tags/tags.utils';
import { captureException } from '@sentry/react';
import { useSearchStore } from 'store/search.store';

const useTags = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [tags, setTags] = useState<string[] | undefined>(undefined);

  const { setTags: setStoreTags } = useSearchStore();

  useEffect(() => {
    const subscription = getAllTagsObservable().subscribe({
      next: (allTags) => {
        if (allTags) {
          const grouped = allTags.reduce((accumulator: { [key: string]: number }, obj: TagDocument) => {
            accumulator[obj.name] = (accumulator[obj.name] || 0) + 1;
            return accumulator;
          }, {});

          const sortedTags = sortTags(grouped);
          setTags(sortedTags);
          setStoreTags(sortedTags);
          setIsLoading(false);
        }
      },
      error: (error) => {
        captureException(error);
        setIsLoading(false);
      },
    });

    return () => {
      subscription.unsubscribe();
      setIsLoading(false);
    };
  }, []);

  return {
    tags,
    isLoading,
  };
};

export default useTags;
