import { isValidDate } from 'utilities/date.utils';

const LOCAL_STORAGE_LAST_SYNCED_DUMPS = 'lsd';
const LOCAL_STORAGE_LAST_SYNCED_ATTACHMENTS = 'lsa';

export function setLocalStorageLastSyncedDumps(value: string): void {
  localStorage.setItem(LOCAL_STORAGE_LAST_SYNCED_DUMPS, value);
}

export function getLocalStorageLastSyncedDumps(): string | undefined {
  const value = localStorage.getItem(LOCAL_STORAGE_LAST_SYNCED_DUMPS);
  if (!value || !isValidDate(value)) {
    return;
  }

  return value;
}

export function setLocalStorageLastSyncedAttachments(value: string): void {
  localStorage.setItem(LOCAL_STORAGE_LAST_SYNCED_ATTACHMENTS, value);
}

export function getLocalStorageLastSyncedAttachments(): string | undefined {
  const value = localStorage.getItem(LOCAL_STORAGE_LAST_SYNCED_ATTACHMENTS);
  if (!value || !isValidDate(value)) {
    return;
  }

  return value;
}

export function clearLocalStorageItems(): void {
  localStorage.removeItem(LOCAL_STORAGE_LAST_SYNCED_DUMPS);
  localStorage.removeItem(LOCAL_STORAGE_LAST_SYNCED_ATTACHMENTS);
}
