import React, { forwardRef } from 'react';
import classNames from 'classnames';

interface ITextarea {
  rows?: number;
  name: string;
  placeholder?: string;
  value?: string;
  onChange?: (arg: any) => any;
  status?: '' | 'disabled' | 'invalid';
}

const Textarea = forwardRef<HTMLTextAreaElement, ITextarea>(
  ({ name, placeholder, rows = 4, value, onChange, status }: ITextarea, ref) => {
    const inputClassNames = classNames(
      'text-dark dark:text-white w-full rounded-xl p-3 tracking-wider dark:bg-paperdark ring-1 ring-gray-300 dark:ring-bright/25',
      {
        'ring-transparent focus-within:outline-none focus-within:border-none focus:ring-cyan': status !== 'invalid',
        'ring-transparent border-1 border-amber-600 focus-within:outline-none ring-0 ': status === 'invalid',
        'opacity-50': status === 'disabled',
      },
    );

    return (
      <textarea
        ref={ref}
        id={name}
        name={name}
        placeholder={placeholder}
        className={inputClassNames}
        aria-multiline={true}
        onChange={(e) => {
          onChange && onChange(e);
        }}
        value={value}
        rows={rows}
      ></textarea>
    );
  },
);

Textarea.displayName = 'Textarea';

export default Textarea;
