import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { useHotkeys } from 'react-hotkeys-hook';
import { getUrlDumps } from 'utilities/urls.utils';
import { Button, ContentLayout, Menu, Text, Tooltip } from 'components/common/ComponentLibrary';
import { ChevronLeftIcon, DocumentDuplicateIcon, EllipsisVerticalIcon, TrashIcon } from '@heroicons/react/24/outline';
import { IRoute } from '../routes';
import { DumpCopyAction, DumpDeleteAction } from 'components/dumps/Actions';
import BackdropComponent from 'components/common/dialog/Backdrop.component';
import ConfirmationDialog from 'components/common/dialog/ConfirmationDialog.component';
import { ROUTE_DIRECTION_ENUM, ROUTE_MOTION_VARIANTS } from 'utilities/motion.utils';
import useDocumentTitle from 'hooks/application/useDocumentTitle';
import { DumpEditForm } from 'components/dumps/Edit';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import { DumpItIcon } from 'components/common/SpecialIcons';
import { formatDistanceToNowInUsersTimezone } from 'utilities/date.utils';
import useUpdateDump from 'hooks/dumps/useUpdateDump';
import { shortenString } from 'utilities/common/strings.utils';
import { DumpInterface } from 'models/dumps/interfaces/DumpInterface';
import { AttachmentInterface } from 'models/attachments/interfaces/AttachmentInterface';
import { useTranslation } from 'react-i18next';

const DumpDetailEditModeScreen = ({
  dump,
  attachments,
}: {
  dump: DumpInterface;
  attachments?: AttachmentInterface[];
}) => {
  const { t } = useTranslation('translations');
  const navigate = useNavigate();

  const [showUnsavedChangesDialog, setShowUnsavedChangesDialog] = useState<boolean>(false);

  const { updateDump } = useUpdateDump();

  useDocumentTitle(dump?.text ? shortenString(dump.text, 20) : 'Your Dump');

  useHotkeys('left', () => navigate(getUrlDumps(), { state: { previousRoute: 'dump_detail' } }), []);

  const handleGoBack = () => {
    setShowUnsavedChangesDialog(false);
    navigate(getUrlDumps(), { state: { previousRoute: 'dump_detail' as IRoute } });
  };

  const location = useLocation();
  const isBackwards = location.state?.previousRoute === getUrlDumps();

  return (
    <motion.div
      custom={{ direction: isBackwards ? ROUTE_DIRECTION_ENUM.BACKWARD : ROUTE_DIRECTION_ENUM.FORWARD }}
      initial='initial'
      animate='in'
      variants={ROUTE_MOTION_VARIANTS}
    >
      <Formik
        onSubmit={async (formData, { resetForm }) => {
          await updateDump(dump, attachments, formData.text, formData.attachments);

          toast.info('Dump updated', {
            autoClose: 2000,
            icon: <DumpItIcon />,
          });

          resetForm();
        }}
        initialValues={{
          text: dump.text || '',
          attachments: attachments,
        }}
        enableReinitialize={true}
      >
        {({ submitForm, resetForm, dirty }) => (
          <ContentLayout hasHScreen={true}>
            <ContentLayout.Top>
              <div className='flex items-center justify-between'>
                <Button
                  size='l'
                  theme='bare'
                  width='none'
                  onClick={() => {
                    if (dirty) {
                      setShowUnsavedChangesDialog(true);
                      return;
                    }

                    handleGoBack();
                  }}
                >
                  <Button.Icon icon={<ChevronLeftIcon className='w-6' />} />
                </Button>

                <div>
                  <Tooltip
                    width='full'
                    size='m'
                    spacing='none'
                    target={
                      <Button size='l' theme='bare' width='none' color='white'>
                        <Button.Icon icon={<EllipsisVerticalIcon className='h-6 text-cyan' />} />
                      </Button>
                    }
                    isInteractive={true}
                    scope='global'
                    trigger='click'
                  >
                    <Menu>
                      <DumpCopyAction dump={dump}>
                        {({ copyDump }: { copyDump: () => void }) => (
                          <Menu.Item onClick={copyDump} icon={<DocumentDuplicateIcon className='h-5' />}>
                            <Text color='inherit'>{t('screens.list.contextMenu.copy')}</Text>
                          </Menu.Item>
                        )}
                      </DumpCopyAction>
                      <DumpDeleteAction dump={dump}>
                        {({ deleteDump }: { deleteDump: () => void }) => (
                          <Menu.Item onClick={deleteDump} icon={<TrashIcon className='w-6' />}>
                            <Text color='inherit'>Delete</Text>
                          </Menu.Item>
                        )}
                      </DumpDeleteAction>
                      <Menu.Item>
                        <Text color='gray-500' textAlign='center' size='s'>
                          {`${dump?.last_edited_at ? 'Updated' : 'Created'}`}{' '}
                          {formatDistanceToNowInUsersTimezone(dump.last_edited_at ?? dump.created_at)}
                        </Text>
                      </Menu.Item>
                    </Menu>
                  </Tooltip>
                </div>

                {showUnsavedChangesDialog && dump && (
                  <BackdropComponent dismiss={() => setShowUnsavedChangesDialog(false)}>
                    <ConfirmationDialog
                      title='Save changes?'
                      leftItem={{ title: 'No', action: handleGoBack, className: 'hover:bg-red/10 text-red' }}
                      rightItem={{
                        title: 'Yes',
                        action: async () => {
                          await submitForm();
                          handleGoBack();
                        },
                      }}
                    />
                  </BackdropComponent>
                )}
              </div>
            </ContentLayout.Top>
            <ContentLayout.Content>
              <div className='flex flex-col flex-1'>
                <DumpEditForm dump={dump} onSave={submitForm} onCancel={() => resetForm()} />
              </div>
            </ContentLayout.Content>
          </ContentLayout>
        )}
      </Formik>
    </motion.div>
  );
};

export default DumpDetailEditModeScreen;
