import SearchInput from 'components/search/SearchInput';
import React from 'react';
import { Tags } from 'components/dumps/Tags';
import useTags from 'hooks/tags/useTags';
import { useTranslation } from 'react-i18next';

interface IDumpsFilter {
  searchTerm?: string | undefined;
  searchBarRef: React.MutableRefObject<HTMLInputElement | null>;
  onSearch: (query: string) => void;
}

const DumpsFilter = ({ searchTerm, onSearch, searchBarRef }: IDumpsFilter) => {
  const { tags } = useTags();
  const { t } = useTranslation('translations');

  return (
    <div className='mb-5'>
      <div className='mb-5'>
        <SearchInput
          defaultValue={searchTerm || ''}
          placeholder={t('searchPlaceholder')}
          searchRef={searchBarRef}
          onChange={onSearch}
        />
      </div>
      {tags && tags.length > 0 && <Tags />}
    </div>
  );
};

export default DumpsFilter;
