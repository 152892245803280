import { mountStoreDevtool } from 'simple-zustand-devtools';
import { create } from 'zustand';
import { SettingInterface } from 'models/settings/interfaces/SettingInterface';
import supabase from './supabase/supabase';
import { captureException } from '@sentry/react';
import { SupabaseSettingService } from 'models/settings/services/supabase/SupabaseSettingService';

export type ISettings = {
  settings: SettingInterface | null;
  isGlobalSearchVisible: boolean;
  setGlobalSearchVisibility: (isVisible: boolean) => void;

  getSettings: () => Promise<SettingInterface | null>;
  setSettings: (settings: SettingInterface | null) => void;

  updateSettings: (data: Partial<SettingInterface>) => Promise<void>;
};

export const useSettingsStore = create<ISettings>((set, get) => ({
  settings: null,

  isGlobalSearchVisible: false,

  setGlobalSearchVisibility: (isVisible) =>
    set((state) => ({
      ...state,
      isGlobalSearchVisible: isVisible,
    })),

  getSettings: async () => {
    try {
      const supabaseSettingService = new SupabaseSettingService(supabase);

      const gotSettings = await supabaseSettingService.getCurrentUsersSettings();
      if (!gotSettings) {
        return null;
      }

      set((state) => ({
        ...state,
        settings: gotSettings,
      }));

      return gotSettings;
    } catch (e) {
      captureException(e);
      return null;
    }
  },

  setSettings: (settings) => {
    set((state) => ({
      ...state,
      settings,
    }));
  },

  updateSettings: async (settings: Partial<SettingInterface>) => {
    let currentSettings = get().settings;
    if (!currentSettings || !currentSettings.id) {
      currentSettings = await get().getSettings();
    }
    if (!currentSettings) {
      throw new Error('Settings not found');
    }

    try {
      const supabaseSettingService = new SupabaseSettingService(supabase);
      const updatedSettings = await supabaseSettingService.updateSettings(currentSettings.id, settings);

      set((state) => ({
        ...state,
        settings: updatedSettings,
      }));
    } catch (e) {
      captureException(e);
    }
  },
}));

mountStoreDevtool('SettingsStore', useSettingsStore);
