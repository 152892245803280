import React, { ComponentPropsWithoutRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useAuthStore } from 'store/auth.store';
import { isUUID } from 'utilities/uuid.utils';
import { getUrlHome } from 'utilities/urls.utils';
import { Button, Heading } from 'components/common/ComponentLibrary';
import useDocumentTitle from 'hooks/application/useDocumentTitle';

type Props = {
  dumpId?: string;
} & ComponentPropsWithoutRef<'div'>;

const PageNotFound: React.FC<Props> = () => {
  const { pathname } = useLocation();
  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);

  useDocumentTitle('Not found');

  const isDumpDetailUrl = (() => {
    const parts = pathname.split('/');
    return parts.length === 3 && parts[1] === 'dumps' && isUUID(parts[2]);
  })();

  const title = isDumpDetailUrl ? 'Dump not found' : 'Page not found';
  const ctaBtn = isAuthenticated ? 'Dump it!' : 'Continue';

  return (
    <div className='flex flex-col w-full h-screen justify-center items-center'>
      <div className='flex flex-col justify-center items-center text-dark dark:text-white'>
        <img
          alt='Dump it!'
          src={`${process.env.PUBLIC_URL}/logo/logo1024-bw.png`}
          className='animate-pulse w-40 aspect-square'
        />
        <Heading size='xxl' level={0} spacing='l'>
          {title}
        </Heading>
        <div className='flex flex-col text-center items-center justify-center gap-y-1 py-6'>
          <Button size='l' href={getUrlHome()}>
            {ctaBtn}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;
