import React from 'react';
import { MinusCircleIcon } from '@heroicons/react/24/solid';
import { MotionEffectPopIn } from 'components/common/MotionEffects';
import { Text } from 'components/common/ComponentLibrary';
import { getFileExtension } from 'utilities/common/file.utils';
import { Link } from 'react-router-dom';

const GenericFilePreview = ({
  src,
  filename,
  onRemove,
}: {
  filename: string;
  src?: string | null;
  onRemove?: () => void;
}) => {
  const extension = getFileExtension(filename);

  let content = (
    <div className='relative'>
      <div className='absolute hover:bg-cyan/30 rounded-xl w-full h-full' />
      <div className='w-18 h-18 object-cover rounded-xl bg-bright dark:bg-dark flex flex-col justify-between'>
        <div className={`w-full h-full text-cyan/50 flex items-center justify-center`}>
          <Text size='m' lineClamp={1} color='inherit' fontWeight='bold'>
            {extension?.toUpperCase()}
          </Text>
        </div>
        <div className={`bg-cyan/50 flex items-center justify-center w-full h-8 px-2 rounded-bl-xl rounded-br-xl`}>
          <p className='line-clamp-1 text-xs text-[#238181] dark:text-[#7ec7c7] font-semibold'>
            {filename.toLowerCase()}
          </p>
        </div>
      </div>
    </div>
  );
  if (src) {
    content = (
      <Link to={src} download={filename}>
        {content}
      </Link>
    );
  }

  return (
    <div className='relative inline-block group'>
      {onRemove && (
        <MinusCircleIcon
          className='w-6 text-red/60 cursor-pointer hidden group-hover:block absolute z-[500] -right-2 -top-2 hover:text-red rounded-2xl'
          onClick={onRemove}
        />
      )}
      <div className='cursor-pointer w-18 h-18'>
        <MotionEffectPopIn>{content}</MotionEffectPopIn>
      </div>
    </div>
  );
};

export default GenericFilePreview;
