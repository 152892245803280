import React, { ComponentPropsWithoutRef } from 'react';
import SettingRowContent from './subComponents/SettingRowContent';
import SettingRowLabel from './subComponents/SettingRowLabel';
import SettingRowEnd from './subComponents/SettingRowEnd';
import findChildByRole from 'utilities/findChildByRole';
import { Text } from 'components/common/ComponentLibrary';
import classNames from 'classnames';

type Props = ComponentPropsWithoutRef<'li'> & {
  onClick?: () => void;
  color?: 'cyan' | 'red';
  status?: '' | 'disabled';
};

const SettingRow: React.FC<Props> = ({ children, onClick, color = 'cyan', status = '' }) => {
  const label = findChildByRole(children, 'SettingRow.Label');
  const content = findChildByRole(children, 'SettingRow.Content');
  const end = findChildByRole(children, 'SettingRow.End');

  const classname = classNames(
    'flex items-center space-x-3 justify-between px-6 py-4 w-full first:rounded-t-xl last:rounded-b-xl border-b-1 border-dark/10 dark:border-bright/10 last:border-none text-dark dark:text-white bg-white dark:bg-white/5 transition-all duration-100',
    {
      'cursor-pointer': status !== 'disabled',
      'hover:bg-cyan/25 dark:hover:bg-cyan/25': color === 'cyan' && status !== 'disabled',
      'hover:bg-red/25 dark:hover:bg-red/25': color === 'red' && status !== 'disabled',
    },
  );

  return (
    <li onClick={onClick} className={classname}>
      <div className='flex flex-col space-y-1'>
        {label && (
          <Text size='l' fontWeight='bold'>
            {label}
          </Text>
        )}
        {content && (
          <Text tag='div' size='s'>
            {content}
          </Text>
        )}
      </div>
      <div>{end}</div>
    </li>
  );
};

export default Object.assign(SettingRow, { Content: SettingRowContent, Label: SettingRowLabel, End: SettingRowEnd });
