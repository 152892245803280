import React from 'react';
import { Field } from 'formik';
import { Label, Text } from 'components/common/ComponentLibrary';
import { FormikValues } from 'formik/dist/types';

export type InputParams = {
  field: {
    name: string;
    value: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  };
  meta: { touched: boolean; error?: string };
};

interface IFormikField {
  name: string;
  label?: string;
  validate?: (value: string) => string | Promise<string> | undefined;
  input: (_: InputParams) => React.ReactNode;
}

const FormikField = ({ name, label, input, validate }: IFormikField) => {
  const renderLabel = () => {
    if (!label) {
      return null;
    }

    return <Label name={name}>{label}</Label>;
  };

  return (
    <Field name={name} validate={validate}>
      {({ field, meta }: FormikValues) => {
        let errorMessage;
        if (meta.touched) {
          errorMessage = meta.error;
          if (meta.error) {
            if (Array.isArray(meta.error)) {
              errorMessage = meta.error
                .filter(Boolean)
                .map((err: string[]) => err.toString())
                .join(' · ');
            } else if (typeof meta.error === 'object') {
              errorMessage = Object.values(meta.error);
            } else {
              errorMessage = meta.error.toString();
            }
          }
        }

        return (
          <div className='space-y-2 h-full w-full'>
            <div className='h-full'>
              {renderLabel()}
              {input({ field, meta })}
            </div>
            {errorMessage && (
              <Text size='s' color='red'>
                {errorMessage}
              </Text>
            )}
          </div>
        );
      }}
    </Field>
  );
};

export default FormikField;
