import React, { useEffect, useRef, useState } from 'react';
import { TrashIcon, MicrophoneIcon } from '@heroicons/react/24/outline';

import { Button, Text } from 'components/common/ComponentLibrary';
import { formatSeconds } from 'utilities/date.utils';
import { MAX_RECORDING_TIME_SECONDS } from 'constants/dumps.constants';
import { useAudioRecorder } from 'react-audio-voice-recorder';
import { convertWebmToWav } from 'utilities/audio.utils';
import { toast } from 'react-toastify';
import { StopIcon } from '@heroicons/react/24/solid';

const AudioRecorder = ({ onDone, onSave }: { onDone: () => void; onSave: (audioBlob: File) => void }) => {
  const [shouldSave, setShouldSave] = useState<boolean>(false);
  const [localRecordingTime, setLocalRecordingTime] = useState(0);
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  const { startRecording, stopRecording, isRecording, recordingBlob } = useAudioRecorder(
    {
      channelCount: 1,
      sampleRate: 16000,
      sampleSize: 16,
    },
    () => toast.info(`Microphone permission is denied. Please enable it in your browser settings.`),
  );

  useEffect(() => {
    startRecording();
    timerRef.current = setInterval(() => {
      setLocalRecordingTime((prevTime) => prevTime + 1);
    }, 1000);

    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, [isRecording]);

  useEffect(() => {
    if (localRecordingTime >= MAX_RECORDING_TIME_SECONDS) {
      setShouldSave(true);
      stopRecording();
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    }
  }, [localRecordingTime]);

  useEffect(() => {
    if (recordingBlob && shouldSave) {
      (async () => {
        const wav = await convertWebmToWav(recordingBlob);
        onSave(wav);
        onDone();
      })();
    }
  }, [recordingBlob, shouldSave]);

  return (
    <div className='flex flex-col items-center justify-center p-4'>
      <div className='flex items-center space-x-4'>
        <Button
          onClick={() => {
            stopRecording();
            onDone();
          }}
          color='white'
          width='square'
          size='s'
        >
          <Button.Icon icon={<TrashIcon className='w-4' />} />
        </Button>

        <div>
          <Text>{formatSeconds(localRecordingTime)}</Text>
        </div>

        {isRecording && (
          <Button
            onClick={async () => {
              stopRecording();
              setShouldSave(true);
            }}
            color='red'
            width='square'
            size='l'
          >
            <Button.Icon icon={<StopIcon className='w-8' />} />
          </Button>
        )}

        {!isRecording && (
          <Button onClick={startRecording} color='red' width='square' size='l'>
            <Button.Icon icon={<MicrophoneIcon className='w-6' />} />
          </Button>
        )}
      </div>
    </div>
  );
};

export default AudioRecorder;
