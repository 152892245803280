import { RxDocument } from 'rxdb';
import { TagInterface } from 'models/dumps/interfaces/TagInterface';
import { TagDocumentType } from './tag.schema';

export type TagDocumentMethods = {
  asTag: () => TagInterface;
};
export type TagDocument = RxDocument<TagDocumentType, TagDocumentMethods>;

export const defaultTagDocumentMethods: TagDocumentMethods = {
  asTag: function (this: TagDocument) {
    return {
      id: this.id,
      name: this.name,
      dump_id: this.dump_id,
      updated_at: this.updated_at,
      created_at: this.created_at,
    };
  },
};
