import React from 'react';
import DumpTags from 'components/dumps/DumpTags';
import { AttachmentInterface } from 'models/attachments/interfaces/AttachmentInterface';
import { DumpInterface } from 'models/dumps/interfaces/DumpInterface';
import DumpReadModeAttachments from './DumpReadModeAttachments';

const DumpReadMode = ({ dump, attachments }: { dump: DumpInterface; attachments?: AttachmentInterface[] }) => (
  <div className='flex flex-1 flex-col md:justify-end md:items-center md:bg-bright md:dark:bg-dark'>
    <div className='flex flex-1 flex-col w-full'>
      {dump?.tags && dump?.tags?.length > 0 && <DumpTags dump={dump} />}

      <div className='relative w-full flex flex-1 flex-col md:dark:bg-paperdark md:border-1 md:border-t-1 border-gray-200 md:p-5 dark:border-bright/25 md:bg-white rounded-xl'>
        <div className='grow outline-none resize-none bg-transparent tracking-wide h-full dark:text-white text-paperdark w-full whitespace-pre-wrap break-all'>
          {dump.text}
        </div>

        <div className='flex flex-row space-x-2'>
          <DumpReadModeAttachments attachments={attachments} />
        </div>
      </div>
    </div>
  </div>
);

export default DumpReadMode;
