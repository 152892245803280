import { DumpInterface } from 'models/dumps/interfaces/DumpInterface';
import { createTags, deleteTagsByDumpId } from 'models/tags/services/rxDb/RxDbTagService';
import { extractTags } from 'models/dumps/services/rxDb/RxDbDumpService';

export const dumpChangesListener = async (change: {
  operation: 'DELETE' | 'INSERT' | 'UPDATE';
  documentData: DumpInterface;
}) => {
  switch (change.operation) {
    case 'DELETE':
      await deleteTagsByDumpId(change.documentData.id);
      break;
    case 'INSERT':
      await createTags(extractTags(change.documentData.text), change.documentData.id);
      break;
    case 'UPDATE':
      await deleteTagsByDumpId(change.documentData.id);
      await createTags(extractTags(change.documentData.text), change.documentData.id);
      break;
    default:
      break;
  }
};
