export enum ROUTE_DIRECTION_ENUM {
  FORWARD = 'forward',
  BACKWARD = 'backward',
}

export const ROUTE_MOTION_VARIANTS = {
  initial: ({ direction }: { direction: ROUTE_DIRECTION_ENUM }) => ({
    opacity: 0,
    x: direction === ROUTE_DIRECTION_ENUM.BACKWARD ? '-100%' : '100%',
    transition: {
      type: 'tween',
      duration: 0.2,
      delay: 0,
    },
  }),
  in: {
    x: 0,
    opacity: 1,
    transition: {
      type: 'tween',
      duration: 0.2,
      delay: 0,
    },
  },
};
