import React, { useState } from 'react';
import ImageUploader from './ImageUploader';
import ImageCropperModal from './ImageCropperModal';
import { ImageListType } from 'react-images-uploading/dist/typings';
import { Avatar } from 'components/common/ComponentLibrary';
import { ArrowUpTrayIcon, MinusCircleIcon } from '@heroicons/react/24/outline';
import { UserIcon } from '@heroicons/react/24/solid';

const ProfileImageUploader = ({ imageUrl, onFinish }: { imageUrl?: string; onFinish: (data: any) => void }) => {
  const [image, setImage] = useState<ImageListType>([]);
  const [showCropperModal, setShowCropperModal] = useState<boolean>(false);

  return (
    <div>
      <ImageUploader
        value={image}
        onChange={(newImage: ImageListType) => {
          setShowCropperModal(true);
          setImage(newImage);
        }}
      >
        {({ onClick }: { onClick: () => void }) => {
          if (!imageUrl) {
            return (
              <div
                className='group relative flex w-40 h-40 cursor-pointer items-center justify-center rounded-full border-2 dark:border-zinc-600 border-zinc-300 text-zinc-400 dark:text-zinc-600'
                onClick={onClick}
              >
                <UserIcon className='w-3/4 group-hover:hidden' />
                <ArrowUpTrayIcon className='hidden w-3/4 group-hover:block' />
              </div>
            );
          }

          return (
            <div className='cursor-pointer relative group' onClick={onClick}>
              <MinusCircleIcon
                className='w-6 text-red cursor-pointer hidden group-hover:block z-500 absolute right-0'
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  onFinish(undefined);
                }}
              />
              <Avatar src={imageUrl} alt='Profile Image' size='xxl' />
            </div>
          );
        }}
      </ImageUploader>
      {showCropperModal && image?.[0].dataURL && (
        <ImageCropperModal
          onDismiss={() => setShowCropperModal(false)}
          image={image[0]}
          onComplete={(imagePromise) => {
            imagePromise.then((data: { croppedImage: string; file: File }) => {
              onFinish(data);
              setShowCropperModal(false);
            });
          }}
        />
      )}
    </div>
  );
};

export default ProfileImageUploader;
