import React, { ReactElement, ReactNode, cloneElement } from 'react';

type JoinChildrenProps = {
  children: ReactNode | ReactElement[];
  separator: ReactElement;
};

const JoinChildren: React.FC<JoinChildrenProps> = ({ children, separator }) => {
  const filteredChildren: ReactElement[] = React.Children.toArray(children).filter((child): child is ReactElement => {
    return React.isValidElement(child);
  });

  if (filteredChildren.length === 0) {
    return null;
  }

  const joinedChildren = filteredChildren.reduce<ReactNode[]>((result, child, index) => {
    if (index < filteredChildren.length - 1) {
      return [...result, child, cloneElement(separator, { key: `separator-${index}` })];
    }
    return [...result, child];
  }, []);

  return <div className='flex items-center space-x-1'>{joinedChildren}</div>;
};

export default JoinChildren;
