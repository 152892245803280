import React, { ComponentPropsWithoutRef } from 'react';

type Props = { children: React.ReactNode } & ComponentPropsWithoutRef<'div'>;

const DividerText: React.FC<Props> = ({ children }) => {
  return (
    <div className='flex items-center'>
      <div className='flex flex-1 h-[1px] bg-dark/25 dark:bg-bright/25'></div>
      <span className='flex px-3'>
        <div className='text-dark/25 dark:text-bright/25'>{children}</div>
      </span>
      <div className='flex flex-1 h-[1px] bg-dark/25 dark:bg-bright/25'></div>
    </div>
  );
};

export default DividerText;
