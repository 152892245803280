import React, { ReactNode } from 'react';
import ImageUploading from 'react-images-uploading';
import { ImageListType } from 'react-images-uploading/dist/typings';

interface IImageUploader {
  value: ImageListType;
  onChange: (newImage: ImageListType) => void;
  children: (props: { onClick: () => void }) => ReactNode;
}

const ImageUploader = ({ value, onChange, children }: IImageUploader) => (
  <ImageUploading value={value} onChange={onChange}>
    {({ onImageUpload, onImageUpdate }) => {
      return children({
        onClick: value ? onImageUpload : () => onImageUpdate(0),
      });
    }}
  </ImageUploading>
);

export default ImageUploader;
