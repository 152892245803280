import { PLAYSPEED } from 'constants/playspeed.constants';
import { useState } from 'react';

const usePlayspeed = () => {
  const [playspeed, setPlayspeed] = useState<PLAYSPEED>(PLAYSPEED.NORMAL);

  const handleSetPlayspeed = async (_playspeed: PLAYSPEED) => {
    await setPlayspeed(_playspeed);
  };

  return { setPlayspeed: handleSetPlayspeed, playspeed };
};

export default usePlayspeed;
