export const sortTags = (tags: { [tag: string]: number }): string[] => {
  const itemsArray = Object.entries(tags);

  const sortedItemsArray = itemsArray.sort((a, b) => {
    if (b[1] - a[1] !== 0) {
      return b[1] - a[1];
    }
    return a[0].toLowerCase().localeCompare(b[0].toLowerCase());
  });

  return sortedItemsArray.map((item) => item[0]);
};
