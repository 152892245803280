import { HighlightedText } from 'components/common/HighlightedText.util';
import React, { useState } from 'react';

const DumpTagItem = (props: {
  tag: string;
  searchTerm: string;
  onToggleTag: (searchTerm: string) => void;
  isActive?: boolean;
}) => {
  const { tag, searchTerm, onToggleTag, isActive: upstreamIsActive = false } = props;
  const [isActive, setIsActive] = useState<boolean>(upstreamIsActive);
  const [isHovered, setIsHovered] = useState<boolean>(false);

  return (
    <li key={tag}>
      <button
        onClick={(e) => {
          e.preventDefault();
          onToggleTag(searchTerm === tag ? '' : tag);
        }}
        className={`px-4 py-2 ${
          isActive || isHovered || searchTerm.toLowerCase() === tag.toLowerCase()
            ? `bg-cyan/10 hover:bg-cyan/25 text-cyan`
            : 'text-dark dark:text-white bg-dark/10 dark:bg-white/10 hover:bg-dark/25 hover:dark:bg-white/25'
        } transition-all duration-200 ease-in-out rounded-xl focus-within:bg-dark/15 focus-within:dark:bg-white/15 focus-within:outline-0 focus-within:ring-0 focus-within:outline-none`}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onBlur={() => setIsActive(false)}
        onFocus={() => setIsActive(true)}
      >
        #{searchTerm.length > 0 ? <HighlightedText text={tag} highlight={searchTerm} /> : tag}
      </button>
    </li>
  );
};

export default DumpTagItem;
