import React from 'react';

import { format } from 'date-fns';
import { Text, Hyperlink } from 'components/common/ComponentLibrary';
import {
  getUrlAndroidAppStore,
  getUrlCookiePolicy,
  getUrlImpressum,
  getUrlPrivacy,
  getUrlTerms,
} from 'utilities/urls.utils';

const Footer = () => (
  <div className='flex flex-col items-center justify-between py-5 px-5 md:flex-row'>
    <div>
      <Text color='gray-500' size='s'>{`© ${format(new Date(), 'yyyy')} Dump it!`}</Text>
    </div>
    <div className='flex flex-col items-center md:flex-row space-y-1 md:space-x-5 mt-2 md:mt-0'>
      {/*<Text size='s' tag='div' color='gray-500'>*/}
      {/*  <Hyperlink href={getUrlIOSAppStore()}>Download iOS app</Hyperlink>*/}
      {/*</Text>*/}
      <Text size='s' tag='div' color='gray-500'>
        <Hyperlink href={getUrlAndroidAppStore()}>Download Android app</Hyperlink>
      </Text>
      <Text size='s' tag='div' color='gray-500'>
        <Hyperlink href={getUrlTerms()}>Terms</Hyperlink>
      </Text>
      <Text size='s' tag='div' color='gray-500'>
        <Hyperlink href={getUrlPrivacy()}>Privacy</Hyperlink>
      </Text>
      <Text size='s' tag='div' color='gray-500'>
        <Hyperlink href={getUrlCookiePolicy()}>Cookies</Hyperlink>
      </Text>
      <Text size='s' tag='div' color='gray-500'>
        <Hyperlink href={getUrlImpressum()}>Impressum</Hyperlink>
      </Text>
    </div>
  </div>
);

export default Footer;
