import { logout } from 'models/application/services/AuthenticationService';

const useLogout = () => {
  const handleLogout = async () => {
    await logout();
  };

  return { logout: handleLogout };
};

export default useLogout;
