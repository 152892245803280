import React from 'react';
import FormikField from './FormikField';
import { Input } from 'components/common/ComponentLibrary';

interface IFormikInputFieldProps {
  name: string;
  label?: string;
  size?: 's' | 'm' | 'l';
  type: string;
  placeholder: string;
  validate?: (value: string) => string | Promise<string> | undefined;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  autoFocus?: boolean;
}

const FormikInputField: React.FC<IFormikInputFieldProps> = (props) => {
  const { name, size = 'm', label: upstreamLabel, validate, onChange: upstreamOnChange, ...upstreamProps } = props;

  const label = typeof upstreamLabel !== 'string' ? '' : upstreamLabel;

  const renderInput = ({
    field: { name: fieldName, value, onChange },
    meta: { touched, error },
  }: {
    field: {
      name: string;
      value: string;
      onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    };
    meta: { touched: boolean; error?: string };
  }) => (
    <Input
      size={size}
      name={fieldName}
      value={value}
      status={touched && error ? 'invalid' : ''}
      {...upstreamProps}
      onChange={(e) => {
        upstreamOnChange && upstreamOnChange(e);
        onChange(e);
      }}
    />
  );

  return <FormikField name={name} input={renderInput} label={label} validate={validate} />;
};

export default FormikInputField;
