import React from 'react';
import DownloadIOSApp from 'components/application/DownloadIOSApp';
import SignupGetStarted from 'components/application/SignupGetStarted';
import DownloadAndroidApp from 'components/application/DownloadAndroidApp';
import Typewriter from 'typewriter-effect';
import { isMobileSafari, isIOS, isAndroid } from 'react-device-detect';

const LandingMain = () => {
  let callToAction = <SignupGetStarted />;
  if (isMobileSafari || isIOS) {
    callToAction = <DownloadIOSApp />;
  }
  if (isAndroid) {
    callToAction = <DownloadAndroidApp />;
  }

  return (
    <>
      <div className='max-w-4xl flex items-center justify-center md:px-0 px-5 mt-20 md:mt-0 mb-24 md:mb-20'>
        <Typewriter
          options={{ cursor: '' }}
          onInit={(typewriter) => {
            typewriter.changeDelay(60).typeString(`Think it. <span class="text-cyan">Dump it!</span>`).start();
          }}
        />
      </div>
      <div className='flex flex-col gap-5 items-center justify-center'>
        <SignupGetStarted />
      </div>
    </>
  );
};

export default LandingMain;
