import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Variants, motion } from 'framer-motion';
import useDumps from 'hooks/dumps/useDumps';
import { debounce, orderBy } from 'lodash';
import { IDump, dumpTagContains, dumpTextContains } from 'models/dump.model';
import SearchInput from './SearchInput';
import { useAuthStore } from 'store/auth.store';
import { DumpRow } from 'components/dumps/DumpList';
import { isMobile } from 'react-device-detect';
import NoDumpSearchMatches from 'components/dumps/NoDumpSearchMatches';
import { useTranslation } from 'react-i18next';

const searchVariants: Variants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
  },
};

type Props = {
  searchTerm: string;
  setSearchTerm: (searchTerm: string) => void;
  dismiss: () => void;
};

const GlobalSearch: React.FC<Props> = (props) => {
  const ulRef = useRef<HTMLUListElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const { dumps } = useDumps();
  const [visibleDumps, setVisibleDumps] = useState(dumps);
  const { searchTerm, setSearchTerm, dismiss, ...motionProps } = props;
  const { t } = useTranslation('translations');

  const [isAuthenticated] = useAuthStore((state) => [state.isAuthenticated]);
  if (!isAuthenticated) {
    return null;
  }

  const handleChange = (query: string) => {
    setSearchTerm(query);
  };
  const debouncedResults = useMemo(() => debounce(handleChange, 300), []);

  useEffect(() => {
    return () => {
      setSearchTerm('');
      debouncedResults.cancel();
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = ({ target }: MouseEvent) => {
      if (containerRef.current && target && !containerRef.current?.contains(target as Node)) {
        dismiss();
      }
    };

    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  useEffect(() => {
    let result: IDump[] = dumps;
    if (searchTerm.length > 0) {
      result = dumps.filter((dump) => dumpTextContains(dump, searchTerm) || dumpTagContains(dump, searchTerm));
    }
    setVisibleDumps(result);
  }, [searchTerm, dumps]);

  return (
    <motion.div
      initial='hidden'
      animate='visible'
      variants={searchVariants}
      exit='hidden'
      transition={{ type: 'tween', duration: 0.3, ease: 'easeOut' }}
      className='flex top-0 left-0 right-0 bottom-0 absolute h-screen w-screen dark:bg-dark/50 backdrop-blur-xl justify-center items-start pt-[15%]'
      {...motionProps}
    >
      <div ref={containerRef} className='flex flex-col mx-4 w-full max-w-md'>
        <div className='pb-5'>
          <SearchInput
            onChange={debouncedResults}
            autoFocus={!isMobile}
            placeholder={t('searchPlaceholder')}
            showClearAction={false}
          />
        </div>
        {searchTerm.length > 0 && visibleDumps.length === 0 && <NoDumpSearchMatches searchTerm={searchTerm} />}
        {searchTerm.length > 0 && visibleDumps.length > 0 && (
          <ul
            ref={ulRef}
            className='flex flex-col mt-2 rounded-xl border-1 border-dark/10 dark:border-bright/10 max-h-[40vh] overflow-y-auto'
          >
            {orderBy(visibleDumps, [(dump) => dump.updated_at ?? dump.created_at, 'created_at'], ['desc', 'desc']).map(
              (dump) => (
                <DumpRow key={dump.id} dump={dump} searchTerm={searchTerm} className='dump-list-row' />
              ),
            )}
          </ul>
        )}
      </div>
    </motion.div>
  );
};

export default GlobalSearch;
