import React, { useState } from 'react';

import { Button, Text } from 'components/common/ComponentLibrary';
import LoginModal from './LoginModal';

const Login = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div>
      <Text size='l' tag='div' fontWeight='bold' spacing='s'>
        Already have an account?
      </Text>
      <Button onClick={() => setIsOpen(true)} theme='ghost' width='full' size='l'>
        Sign in
      </Button>
      {isOpen && <LoginModal onDismiss={() => setIsOpen(false)} />}
    </div>
  );
};

export default Login;
