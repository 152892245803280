import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { trackEvent } from 'models/application/services/TrackingService';
import { TRACKING_EVENT_ENUM } from 'models/application/enums/trackingEventEnum';

const useTrackPageView = (isAuthenticated: boolean) => {
  const location = useLocation();

  useEffect(() => {
    let eventLocation = location.pathname;
    switch (location.pathname) {
      case '/':
        if (isAuthenticated) {
          eventLocation = '/create';
        }
        break;
      default:
        eventLocation = location.pathname;
    }

    trackEvent(TRACKING_EVENT_ENUM.PAGE_VIEW, {
      location: eventLocation,
    });
  }, [location]);
};

export default useTrackPageView;
