import { useEffect, useState } from 'react';
import { DumpDocument } from 'store/rxdb/dump/dump.document';
import { getDumpById } from 'models/dumps/services/rxDb/RxDbDumpService';
import { DumpInterface } from 'models/dumps/interfaces/DumpInterface';
import { AttachmentInterface } from 'models/attachments/interfaces/AttachmentInterface';
import { getAttachmentsByDumpId } from 'models/attachments/services/rxDb/RxDbAttachmentService';
import { AttachmentDocument } from 'store/rxdb/attachment/attachment.document';
import { dumpitDatabase } from 'store/rxdb/dumpitDatabase';

const useDump = (id: string) => {
  const [error, setError] = useState<Error | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [docDump, setDocDump] = useState<DumpDocument | null>(null);
  const [docAttachments, setDocAttachments] = useState<AttachmentDocument[] | undefined>(undefined);
  const [attachments, setAttachments] = useState<AttachmentInterface[] | undefined>(undefined);
  const [dump, setDump] = useState<DumpInterface | undefined>(undefined);

  useEffect(() => {
    (async () => {
      setIsLoading(true);

      try {
        const dump = await getDumpById(id);
        setDocDump(dump);
        setDump(dump?.asDump());
      } catch (error: any) {
        setError(error);
        setIsLoading(false);
        return;
      }

      try {
        const attachments = await getAttachmentsByDumpId(id);
        if (attachments) {
          setDocAttachments(attachments);
          setAttachments(attachments.map((_attachment) => _attachment.asAttachment()));
        }
        setIsLoading(false);
      } catch (error: any) {
        setIsLoading(false);
        // No attachments.
      }
    })();

    return () => {
      setIsLoading(false);
    };
  }, []);

  useEffect(() => {
    const sub = docDump?.$.subscribe((current) => setDump(current.asDump()));
    return () => {
      sub?.unsubscribe();
    };
  }, [docDump]);

  useEffect(() => {
    if (dump) {
      const query = dumpitDatabase.attachments.find().where('dump_id').eq(dump.id);
      const sub = query.$.subscribe((attachments: any) => {
        setDocAttachments(attachments);
      });

      return () => {
        sub?.unsubscribe();
      };
    }
  }, [docDump]);

  useEffect(() => {
    if (docAttachments) {
      const subs = docAttachments.map((docAttachment) => {
        return docAttachment.$.subscribe((current) => {
          setAttachments((prevAttachments) => {
            if (!prevAttachments) return prevAttachments;

            const currentAttachment = current.asAttachment();
            const index = prevAttachments.findIndex((att) => att.id === currentAttachment.id);

            if (currentAttachment._deleted || currentAttachment.is_to_be_deleted) {
              if (index !== -1) {
                const newAttachments = [...prevAttachments];
                newAttachments.splice(index, 1);
                return newAttachments;
              }
            } else {
              if (index !== -1) {
                const newAttachments = [...prevAttachments];
                newAttachments[index] = currentAttachment;
                return newAttachments;
              } else {
                return [...prevAttachments, currentAttachment];
              }
            }

            return prevAttachments;
          });
        });
      });

      return () => {
        subs.forEach((sub) => sub.unsubscribe());
      };
    }
  }, [docAttachments]);

  return {
    dump,
    attachments,
    isLoading,
    error,
  };
};

export default useDump;
