import React from 'react';
import { DumpItIcon } from 'components/common/SpecialIcons';
import Footer from 'components/application/Footer';
import { Heading, Hyperlink, Text } from 'components/common/ComponentLibrary';
import { getUrlHome } from 'utilities/urls.utils';
import useDocumentTitle from 'hooks/application/useDocumentTitle';

const CookiesScreen = () => {
  useDocumentTitle('Cookie Policy');

  return (
    <div className='m-auto flex min-h-screen flex-col'>
      <main className='m-auto w-full max-w-7xl px-5'>
        <div className='flex items-center text-center justify-center my-5'>
          <Hyperlink href={getUrlHome()}>
            <DumpItIcon className='w-24' />
          </Hyperlink>
        </div>

        <Heading size='xxl' spacing='m'>
          Cookie Policy
        </Heading>

        <Text size='l' spacing='m' fontWeight='bold'>
          Updated on August 6th, 2024
        </Text>

        <Text size='l' spacing='m' fontWeight='bold'>
          This Cookie Policy explains what cookies are and how Dump it! ("we," "our," or "us") uses them on
          www.dumpit.app (the "Website"). We encourage you to read the policy in full to understand what information we
          collect using cookies and how that information is used.
        </Text>

        <Text fontWeight='bold' size='l' spacing='m'>
          1. What Are Cookies?
        </Text>
        <Text spacing='m'>
          Cookies are small text files stored on your device (computer, smartphone, tablet) when you visit a website.
          They allow the website to recognize your device and store information about your preferences or past actions
          on the site.
        </Text>

        <Text fontWeight='bold' size='l' spacing='m'>
          2. How We Use Cookies
        </Text>
        <Text>We use cookies to:</Text>
        <Text>
          &middot; Improve website functionality: Cookies help us provide a better user experience by remembering your
          preferences and interactions.
        </Text>
        <Text spacing='m'>
          &middot; Analyze traffic and usage: Cookies allow us to monitor how visitors use the Website and to make
          improvements accordingly.
        </Text>

        <Text fontWeight='bold' size='l' spacing='m'>
          3. Types of Cookies We Use
        </Text>
        <Text>
          &middot; Strictly Necessary Cookies: These are essential for the website to function properly, such as
          enabling you to log in, fill out forms, and access secure areas.
        </Text>
        <Text>
          &middot; Performance and Analytics Cookies: These cookies collect anonymous data about how visitors interact
          with the website. We use this information to improve the functionality of the site. Specifically, we use
          Mixpanel for tracking user interactions and gathering analytics data to help improve our services. Mixpanel
          cookies collect information on how you use the Website, such as page visits, clicks, and other actions.
        </Text>
        <Text spacing='m'>
          &middot; Functionality Cookies: These cookies remember your preferences and choices, such as your language
          settings, so you don’t have to re-enter information during your next visit.
        </Text>

        <Text fontWeight='bold' size='l' spacing='m'>
          4. Third-Party Cookies
        </Text>
        <Text spacing='m'>
          In addition to our own cookies, we use third-party services such as Mixpanel to gather analytics data. These
          third parties may use cookies to collect information about your browsing habits across different websites.
        </Text>

        <Text fontWeight='bold' size='l' spacing='m'>
          5. Managing Cookies
        </Text>
        <Text>You have the right to accept or decline cookies. You can manage your cookie preferences by:</Text>
        <Text>
          &middot; Browser Settings: Most web browsers allow you to control cookies through the browser settings. You
          can set your browser to refuse or delete cookies, but doing so may affect the functionality of the website.
        </Text>
        <Text spacing='m'>
          &middot; Opt-out Mechanisms: Certain third parties, such as Mixpanel, offer opt-out mechanisms for their
          cookies. You can visit{' '}
          <Hyperlink href='https://mixpanel.com/optout/' target='_blank'>
            Mixpanel's Opt-out Page
          </Hyperlink>{' '}
          for more information.
        </Text>

        <Text fontWeight='bold' size='l' spacing='m'>
          6. Changes to This Cookie Policy
        </Text>
        <Text spacing='m'>
          We may update this Cookie Policy from time to time to reflect changes in the law or how we use cookies. Any
          updates will be posted on this page with a new "Last Updated" date.
        </Text>

        <Text fontWeight='bold' size='l' spacing='m'>
          7. Contact Us
        </Text>
        <Text spacing='m'>
          If you have any questions or concerns about this Cookie Policy, please contact us at: hello@dumpit.app
        </Text>
      </main>
      <footer className='m-auto w-full max-w-7xl'>
        <Footer />
      </footer>
    </div>
  );
};

export default CookiesScreen;
