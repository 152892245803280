export function removeItemAtIndex(array: any[], index: number) {
  if (index > -1 && index < array.length) {
    array.splice(index, 1);
  }

  return array;
}

export function uniquifyArray(array: string[]) {
  return [...new Set(array)];
}

export function areArraysEqual(arr1: string[], arr2: string[]): boolean {
  if (arr1.length !== arr2.length) return false;

  const count: { [key: string]: number } = {};

  // Count each element in the first array
  for (const element of arr1) {
    if (count[element]) {
      count[element]++;
    } else {
      count[element] = 1;
    }
  }

  // Decrement the count for each element in the second array
  for (const element of arr2) {
    if (!count[element]) return false; // if the element wasn't in the first array or too many occurrences in the second
    count[element]--;
  }

  // Check if all counts returned to zero
  return Object.values(count).every((x) => x === 0);
}
