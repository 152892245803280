import React, { useState } from 'react';
import { SignUpModal } from './SignUp';
import { Form, Formik } from 'formik';
import { FormikInputField } from '../common/Formik';
import { Button } from 'components/common/ComponentLibrary';

const SignupGetStarted = () => {
  const [isSignUpModalOpen, setIsSignUpModalOpen] = useState(false);
  const [inputEmail, setInputEmail] = useState('');
  return (
    <>
      <Formik
        onSubmit={async ({ email }) => {
          setInputEmail(email);
          setIsSignUpModalOpen(true);
        }}
        initialValues={{ email: '' }}
      >
        {({ isSubmitting }) => (
          <Form>
            <div className='w-full flex flex-col md:flex-row gap-4 px-10 md:px-0'>
              <FormikInputField size='l' type='email' name='email' placeholder='Your email' autoFocus={true} />
              <Button size='l' type='submit' status={isSubmitting ? 'busy' : ''}>
                Get started
              </Button>
            </div>
          </Form>
        )}
      </Formik>
      {isSignUpModalOpen && <SignUpModal onDismiss={() => setIsSignUpModalOpen(false)} email={inputEmail} />}
    </>
  );
};

export default SignupGetStarted;
