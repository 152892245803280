import React, { forwardRef } from 'react';

import { Link, useLocation } from 'react-router-dom';

import ButtonBase from './components/ButtonBase';
import Icon from './subcomponents/Icon';

interface IButton {
  children: React.ReactNode;
  onClick?: () => void;
  target?: string;
  href?: string;
  size?: 's' | 'm' | 'l';
  status?: '' | 'disabled' | 'busy';
  theme?: 'solid' | 'ghost' | 'bare';
  type?: 'button' | 'submit' | 'reset';
  color?: 'default' | 'red' | 'white' | 'green';
  width?: 'auto' | 'full' | 'square' | 'none';
  align?: 'center' | 'left' | 'right';
}

const Button = forwardRef<HTMLButtonElement, IButton>(({ ...props }, ref) => {
  const buttonBaseProps: IButton = {
    theme: props.theme || 'solid',
    size: props.size || 'm',
    color: props.color || 'default',
    status: props.status || '',
    width: props.width || 'auto',
    align: props.align || 'center',
    children: props.children,
    onClick: props.onClick,
    href: props.href,
    target: props.target,
    type: props.type || 'button',
  };

  const location = useLocation();
  return props.href ? (
    <Link to={{ pathname: props.href }} state={{ previousRoute: location.pathname }} className='inline-block'>
      <ButtonBase {...buttonBaseProps} forwardRef={ref}>
        {props.children}
      </ButtonBase>
    </Link>
  ) : (
    <ButtonBase {...buttonBaseProps} forwardRef={ref}>
      {props.children}
    </ButtonBase>
  );
});

Button.displayName = 'Button';

export default Object.assign(Button, { Icon });
