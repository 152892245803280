import supabase from 'store/supabase/supabase';
import { STORAGE_BUCKET_ENUM } from 'models/application/enums/ImageEnums';
import { nanoid } from 'nanoid';
import toWav from 'audiobuffer-to-wav';
import { toast } from 'react-toastify';

export const uploadAudio = async (audioFile: File, path: string): Promise<{ path: string; url: string } | null> => {
  const { data, error } = await supabase.storage.from(STORAGE_BUCKET_ENUM.ATTACHMENTS).upload(path, audioFile, {
    contentType: 'audio/mpeg',
    cacheControl: '3600',
    upsert: true,
  });
  if (error) {
    return null;
  }

  const { data: imageFromStorage } = supabase.storage.from(STORAGE_BUCKET_ENUM.ATTACHMENTS).getPublicUrl(data.path);

  return {
    path: data.path,
    url: imageFromStorage.publicUrl,
  };
};

export const convertWebmToWav = async (webM: Blob): Promise<File> => {
  const audioContext = new AudioContext();

  const arrayBuffer = await webM.arrayBuffer();

  const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);

  const wav = toWav(audioBuffer);

  const wavBlob = new Blob([wav], { type: 'audio/wav' });

  return new File([wavBlob], `${nanoid()}.wav`, { type: 'audio/wav' });
};

export async function getAudioDuration(file: File): Promise<number> {
  try {
    return await new Promise((resolve) => {
      const url = URL.createObjectURL(file);

      const audio = new Audio();

      audio.src = url;

      audio.addEventListener('loadedmetadata', () => {
        resolve(audio.duration);
        URL.revokeObjectURL(url);
      });

      audio.addEventListener('error', () => {
        resolve(0);
      });
    });
  } catch (error) {
    return 0;
  }
}

export const checkMicrophonePermission = async () => {
  if (!navigator.mediaDevices || !navigator.permissions) {
    toast.info('Microphone not supported by your browser.');
    return false;
  }

  try {
    const permissionStatus = await navigator.permissions.query({ name: 'microphone' as any });

    if (permissionStatus.state === 'granted') {
      return true;
    } else if (permissionStatus.state === 'prompt') {
      return true;
    } else {
      toast.info(`Microphone permission is denied. Please enable it in your browser settings.`);
      return false;
    }
  } catch (error) {
    toast.info('Microphone not supported by your browser.');
    return false;
  }
};

export function formatSecondsToHoursMinutes(secondsIn: number): string {
  const hours = Math.floor(secondsIn / 3600)
    .toString()
    .padStart(2, '0');
  const minutes = Math.floor((secondsIn % 3600) / 60);
  const seconds = Math.floor(secondsIn % 60);

  return `${hours === '00' ? '' : `${hours}:`}${minutes.toString().padStart(2, '0')}:${seconds
    .toString()
    .padStart(2, '0')}`;
}
