import React from 'react';

const StickyHeader = ({ children }: { children: React.ReactNode }) => (
  <div
    className='md:fixed w-full md:w-[calc(100%_-_14px)] md:mr-14 z-10 shadow-sm'
    style={{ backdropFilter: 'blur(8px)' }}
  >
    <div className='w-full'>{children}</div>
  </div>
);

export default StickyHeader;
