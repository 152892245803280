import mixpanel from 'mixpanel-browser';
import { getEnvironmentVariable, isProductionEnv } from 'utilities/env.utils';
import { TRACKING_EVENT_ENUM } from 'models/application/enums/trackingEventEnum';
import { ProfileInterface } from 'models/profiles/interfaces/ProfileInterface';

let isInitialized = false;

export function trackEvent(eventName: TRACKING_EVENT_ENUM, data = {}): any {
  if (!isProductionEnv()) {
    return;
  }

  if (!isInitialized) {
    mixpanelInit();
  }

  mixpanel.track(eventName, data);
}

export function mixpanelInit(): any {
  if (isInitialized) {
    return;
  }

  mixpanel.init(getEnvironmentVariable('MIXPANEL_PROJECT_TOKEN'), {
    debug: false,
    track_pageview: true,
    persistence: 'localStorage',
  });

  isInitialized = true;
}

export function mixpanelIdentify(profile: ProfileInterface): any {
  if (!isProductionEnv()) {
    return;
  }

  if (!isInitialized) {
    mixpanelInit();
  }

  mixpanel.identify(profile.id);
  mixpanel.people.set({ name: `${profile.first_name} ${profile.last_name}`, email: profile.email });
}

export function mixpanelReset(): any {
  if (!isProductionEnv()) {
    return;
  }

  if (!isInitialized) {
    mixpanelInit();
  }

  mixpanel.reset();
}
